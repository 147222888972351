import { secureAxios } from "../../http-common";

const policyUrl = 'portal/AzurePolicy';
const cloudResourceUrl = 'portal/CloudResource';

const createPolicyParameters = async (request: CreateOrUpdateAzurePolicyParameterRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${policyUrl}/Parameters`, request);
};

const getAzurePolicyAssignmentCommand = async (category: string = "Location", policyName: string = "FloatFinOpsGovernanceInitiative") => {
    return secureAxios.get<AuthenticationResponse>(`${policyUrl}/AzurePolicyAssignmentCommand/${category}/${policyName}`);
};

const getAzurePolicyDefinitionCommand = async (category: string = "Location", policyName: string = "FloatFinOpsGovernanceInitiative") => {
    return secureAxios.get<AuthenticationResponse>(`${policyUrl}/AzurePolicySetDefinitionCommand/${category}/${policyName}`);
};

const getAzurePolicyParameters = async (category: string = "Location", policyName: string = "FloatFinOpsGovernanceInitiative") => {
    return secureAxios.get<AuthenticationResponse>(`${policyUrl}/Parameters/${category}/${policyName}`);
};

const getResourceLocationDistribution = async () => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceUrl}/GetResourceLocationDistribution`);
};

const getResourcePolicyComplianceStatus = async (request: ResourcePolicyComplianceStatusRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${policyUrl}/GetResourcePolicyComplianceStatus`, request);
};


const PolicyService = {
    createPolicyParameters,
    getAzurePolicyAssignmentCommand,
    getAzurePolicyDefinitionCommand,
    getAzurePolicyParameters,
    getResourceLocationDistribution,
    getResourcePolicyComplianceStatus
};

export default PolicyService;