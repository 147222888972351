import { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AuthenticationService from "../app/service/authService";
import commonService from "../app/service/commonService";
import AccountSelectModal from "./initiatives/modal/accountSelectModal";
import { MyContext } from "../App";
import { useMsal } from "@azure/msal-react";


const resellerNavigation = [
  { name: "Proposals", href: "#", link: "/resellerProposal", id: 5 },
  { name: "Accounts", href: "#", link: "/resellerAccounts", id: 6 },
  { name: "Unmapped Tenants", href: "#", link: "/resellerUnmappedSubscripitons", id: 7 }
];

const customerNavigation = [
  { name: "Onboarding", href: "#", link: "/newAccount", id: 1 },
  { name: "Home", href: "#", link: "/", id: 2 },
  { name: "Initiatives", href: "#", link: "/Initiatives", id: 3 },
  { name: "Cloud Resources", href: "#", link: "/CloudResources", id: 4 },
  { name: "Dynamic Query Screen", href: "#", link: "/DynamicQuery", id: 5 },
];


const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

export function Header() {
  const [activeMenu, setActiveMenu] = useState<string>("Home");
  const [user, setUser] = useState<any>();
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState<string>("");
  const [profileImageError, setProfileImageError] = useState(false);
  const dbkProfile = commonService.dbkProfile;
  const location = useLocation();

  const [dbkAccounts, setAccounts] = useState<AccountItem[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<AccountItem>();

  const {isCustomer}: any = useContext(MyContext);

  const [navigation, setNavigation] = useState<any[]>([]);

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get('customerId');
  const { instance, accounts } = useMsal();

  useEffect(() => {
    initAccounts();
    initNavMenu(selectedAccount);
  }, [isCustomer]);

  useEffect(() => {
    const pathName = window.location.pathname?.toString()?.split('/')[1] || '';
    setMenu(`/${pathName}`);
  }, [location]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentUser") ?? "{}") || {};
    setUser(user);
    initAccounts();
  }, []);
  
  useEffect(() => {
    getUserImage();
  }, [user]);

  useEffect(() => {
    initAccounts();
  }, [commonService.accounts]);


  const initAccounts = () => {
    const allAccounts = commonService.userDetail()?.accounts || commonService.accounts || [];
    const currentAccountId = commonService.selectedAccount?.id || customerId || commonService.currentUser()?.accountId;
    const currentAccountIndex = allAccounts.findIndex((x: AccountItem) => x.id === currentAccountId);
    if(currentAccountIndex > -1) {
      const currentAccount = allAccounts[currentAccountIndex];
      allAccounts.splice(currentAccountIndex, 1);
      allAccounts.unshift(currentAccount);
      setAccounts(allAccounts);
      setSelectedAccount(currentAccount);
      initNavMenu(currentAccount);
    }
  }

  const initNavMenu = (currentAccount: AccountItem | undefined) => {
    const navList = JSON.parse(JSON.stringify(isCustomer ? customerNavigation: resellerNavigation));
    if(currentAccount?.saleStatus === commonService.saleStatus.COMPLETED) {
      const onBoardMenuIndex = (navList || []).findIndex((n: any) => n.id === 1);
      if(onBoardMenuIndex > -1) {
        navList.splice(onBoardMenuIndex, 1);
      }
    }
    setNavigation(navList || []);
  }

  const getUserImage = () => {
    AuthenticationService.getProfileImage()
      .then((response: any) => {
        setProfileImage(response?.data?.result || "");
        localStorage.setItem("profileImage", response?.data?.result || "");
      })
      .catch((e: any) => {
        console.log("error");
      });
  };

  const setMenu = (link: string) => {
    const selectedMenu = navigation.find((x) => x.link === link)?.name || "";
    setActiveMenu(selectedMenu);
  };

  const onNavigationChange = (i: number) => {
    if (i == 2) {
      localStorage.clear();
      if(accounts.length > 0){
        instance.logout();
      }
      sessionStorage.clear();
      navigate('/redirect');
    } else {
      commonService.parseNavigate("/settings", navigate);
    }
  };

  const [showAccountModal, setShowAccountModal] = useState(false);

  const closeAccountModal = (refresh?: boolean) => {
    setShowAccountModal(false);
  };

  const openAccountModal = () => {
    setShowAccountModal(true);
  };

  const onChangeAccount = (accountItem: AccountItem) => {
    if(accountItem) {
      setSelectedAccount(accountItem);
      commonService.redirectPath = JSON.parse(JSON.stringify(location.pathname));
      commonService.parseNavigate('/redirectAccount', navigate);
    }
  }

  return (
    <>
    {showAccountModal && <AccountSelectModal
        show={showAccountModal}
        closeModal={closeAccountModal}
        accounts={dbkAccounts}
        selectedAccount={selectedAccount}
        changeAccount={onChangeAccount}
      />}
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-12 w-auto mt-4"
                    src={dbkProfile.imgUrl}
                    alt={dbkProfile.name}
                  />
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {navigation.map((item) => (
                    <a
                    key={item.name}
                      href="#"
                      onClick={() => commonService.parseNavigate(item.link, navigate)}
                      className={commonService.classNames(
                        activeMenu === item.name
                          ? "border-indigo-500 text-gray-900"
                          : "border-transparent  text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                      )}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  <button
                    type="button"
                    className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {dbkAccounts?.length > 0 ?
                  <button
                  type="button"
                  className="mx-2 relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 truncate max-w-40"
                  onClick={() => openAccountModal()}
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Accounts</span>
                  {selectedAccount?.name} <br />
                  { selectedAccount?.type }
                </button>: 
                <div className="mx-2 relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 truncate max-w-40">
                <span>
                  {selectedAccount?.name}</span>
                  <br />
                  <span>
                  {selectedAccount?.type}</span>
                </div>}

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        
                        {profileImage && !profileImageError ? (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={profileImage}
                            alt={user?.username}
                            onError={() => setProfileImageError(true)}
                          />
                        ) : (
                          <a
                            href="#"
                            data-tooltip-id={user?.id + "-userProfile-tooltip"}
                            className="relative bg-indigo-400 text-white z-30 inline-block h-8 w-8 rounded-full inline-flex flex items-center justify-center"
                          >
                            {(user?.firstName?.toString()[0]?.toUpperCase() || "") +
                              (user?.lastName?.toString()[0]?.toUpperCase() || "")}
                          </a>
                        )}

                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item, i: number) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                onClick={() => onNavigationChange(i)}
                                className={commonService.classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  as="a"
                  href="#"
                  onClick={() => commonService.parseNavigate(item.link, navigate)}
                  className={commonService.classNames(
                    activeMenu === item.name
                      ? "text-indigo-700 border-indigo-500 bg-indigo-50"
                      : "border-transparent  text-gray-500 hover:border-gray-300 hover:text-gray-700 text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 border-transparent",
                    "block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6"
                  )}
                  key={item.name}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4 sm:px-6">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={profileImage}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user?.username}k
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user?.email}
                  </div>
                </div>
                <button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="mx-2 relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 truncate max-w-20"
                  >
                    <span className="absolute -inset-1.5" />Accounts
                  </button>
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    as="a"
                    href={item.href}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                    key={item.name}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    </>
  );
}
