import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";

export let API_CONFIG = {
    AzureB2C: {
        Scope: ["https://floatfinopsglass.onmicrosoft.com/fa52bc86-e319-42c1-a89c-32e4b0593e73/auth.ReadWrite"],
        ClientId: 'fa52bc86-e319-42c1-a89c-32e4b0593e73',
        SignIn: "B2C_1_SignIn",
        SignUp: "B2C_1_SignUp",
        EditProfile: "",
        PasswordReset: "",
        AuthorityDomain: "floatfinopsglass.b2clogin.com",
        ServiceTenant: "floatfinopsglass.onmicrosoft.com",
        SignInAuthority: "https://floatfinopsglass.b2clogin.com/floatfinopsglass.onmicrosoft.com/B2C_1_SignIn",
        SignUpAuthority: "https://floatfinopsglass.b2clogin.com/floatfinopsglass.onmicrosoft.com/B2C_1_SignUp",
        PasswordResetAuthority: "",
    }
}

// src/authConfig.js
export const msalConfig = {
    auth: {
        clientId: API_CONFIG.AzureB2C.ClientId,
        authority: API_CONFIG.AzureB2C.SignInAuthority,
        redirectUri: "https://glass.floatfinops.io/redirect",
        //redirectUri: "http://localhost:3000/redirect",
        knownAuthorities: [API_CONFIG.AzureB2C.AuthorityDomain],
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where the token is stored
        storeAuthStateInCookie: true, // Set to true if you have issues with cookies
    }
};

export const loginRequest = {
    scopes: API_CONFIG.AzureB2C.Scope
};
