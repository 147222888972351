import { StatWithPercentageChange } from "../../app/common/commonControls";
import commonService from "../../app/service/commonService";


interface Props {
    data: MonthCostCategoryComparisonResult[]

}
export default function InvoiceSummary(props: Readonly<Props>) {

    
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>

                                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Category
                                    </th> */}
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Sub Category
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Meter
                                    </th>
                                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Region
                                    </th> */}
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Cost
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Resources
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Unit Consume
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Unit Pice
                                    </th>
                                </tr>
                            </thead>
                             <tbody className="divide-y divide-gray-200">
                                {props.data?.map((item) => (
                                
                                    <tr key={item.id}>
                                        {/* <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0">{item.category}</td> */}
                                        <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0">{item.subCategory.substring(0,24)}</td>
                                        <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0">{item.meterName.substring(0,24)}</td>
                                        {/* <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0">{item.resourceLocation}</td> */}
                                        <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0">
                                            <StatWithPercentageChange stat={commonService.formatToUSCurrency(item.cost)}  prevValue={item.prevCost} currentValue={item.cost} />
                                        </td>
                                        <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0"> 
                                            <StatWithPercentageChange stat={item.resourceCount}  prevValue={item.prevResourceCount} currentValue={item.resourceCount} />
                                        </td>
                                        <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0">
                                            <StatWithPercentageChange stat={item.quantity}  prevValue={item.prevQuantity } currentValue={item.quantity} />
                                        </td>
                                        <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0">
                                            <StatWithPercentageChange stat={commonService.formatToUSCurrency(item.unitPrice)}  prevValue={item.prevUnitPrice } currentValue={item.unitPrice} />
                                        </td>


                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
