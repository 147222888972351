import { secureAxios } from "../../http-common";

const kqlQueryUrl = 'portal/KqlQuery';

const getAllKqlQueries = async () => {
    return secureAxios.get<AuthenticationResponse>(`${kqlQueryUrl}`);
};

const createKqlQuery = async (request: CreateOrUpdateKQLQueryRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${kqlQueryUrl}`, request);
};

const updateKqlQuery = async (request: CreateOrUpdateKQLQueryRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${kqlQueryUrl}`, request);
};

const deleteKqlQuery = async (queryName: string) => {
    return secureAxios.delete<AuthenticationResponse>(`${kqlQueryUrl}/${queryName}`);
};

const executeKqlQuery = async (request: KQLQueryExecuteRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${kqlQueryUrl}/ExecuteKQLQuery`, request);
};

const KQLQueryService = {
    getAllKqlQueries,
    createKqlQuery,
    updateKqlQuery,
    deleteKqlQuery,
    executeKqlQuery
};

export default KQLQueryService;