import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import { ModalWrapper } from "../../common/modalWrapper";
import KQLQueryService from "../../../app/service/kqlQueryService";
import { CodeGroup } from "../../documents";
import * as XLSX from "xlsx";
import CloudResourcesModal from "./cloudResourceModal";
interface Props {
  show: boolean;
  closeModal?: any;
  query?: KQLQueryItem;
  subscriptionList: SubscriptionItem[];
  templates: OpportunityPlanResourceTaskTemplateItem[];
}

export default function KqlQueryExecuteModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);
  const cancelButtonRef = useRef(null);

  const [query, setQuery] = useState<string>(props?.query?.query || "");
  const [selectedSubscription, setSelectedSubscription] = useState<string>("");

  const [jsonData, setJsonData] = useState<any>("");
  const [codeText, setCodeText] = useState<string>("");
  const [rawData, setRawData] = useState<any>([]);

  const [resourceData, setResourceData] = useState<any[]>([]);

  const checkbox = useRef<any>(null);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedResource, setSelectedResource] = useState<any[]>([]);

  const [showCloudResourceModal, setShowCloudResourceModal] = useState<boolean>(false);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
    }
  }, [props.show]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const isValidResource = (resource: any): boolean => {
    const resourceIdRegex = /^\/subscriptions\/[0-9a-fA-F-]+\/resourceGroups\/[a-zA-Z0-9-_()]+\/providers\/[a-zA-Z0-9.]+\/[a-zA-Z0-9-_/]+$/;
    const resourceTypeRegex = /^microsoft\.[^/]+\/[^/]+$/i;
    return resourceIdRegex.test(resource?.id) && resourceTypeRegex.test(resource?.type);
  };

  const parseTableData = (result: any[]) => {
    const data: any = [];
    (result || []).forEach((x) => {
      if (x.id && x.type && isValidResource(x)) {
        data.push({
          name: x.name,
          type: x.type,
          resourceId: x.id,
        });
      }
    });
    setResourceData(data);
  };

  const executeKQLQuery = () => {
    setResourceData([]);
    setSelectedResource([]);
    const request: KQLQueryExecuteRequest = {
      query: query,
      subscriptionId: selectedSubscription,
    };
    setLoading(true);
    KQLQueryService.executeKqlQuery(request)
      .then((response: any) => {
        setLoading(false);
        commonService.showMessage(response?.data || []);
        if (response?.data?.isSuccess) {
          const result = response?.data?.result || [];
          parseTableData(result);
          setRawData(result);
          try {
            const parseData = JSON.parse(result || []);
            setJsonData(parseData);
            setCodeText(result);
          } catch (_) {
            setJsonData(result);
            setCodeText(JSON.stringify(result || []));
          }
        }
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const download = () => {
    const worksheet = XLSX.utils.json_to_sheet(rawData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.utils.sheet_to_csv(worksheet, { FS: "," });
    XLSX.writeFile(workbook, "output4.csv", { bookType: "csv" });
  };

  const toggleAll = () => {
    setSelectedResource(checked || indeterminate ? [] : resourceData || []);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const openCloudResourceModal = () => {
    setShowCloudResourceModal(true);
  }

  const closeCloudResourceModal = (_?: boolean) => {
    setShowCloudResourceModal(false);
  }

  const mainModalDisplay = () => {
    return showCloudResourceModal ? "none" : "initial";
  }

  return (
    <>
    {showCloudResourceModal && (
        <CloudResourcesModal
          show={showCloudResourceModal}
          closeModal={closeCloudResourceModal}
          templates={props?.templates || []}
          resources={selectedResource}
        />
      )}
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6"
      style={{
        display: mainModalDisplay()
      }}>
        <div>
          <div className="mt-3 mb-10 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Query
            </Dialog.Title>
            <div className="mt-2">
              <div>
                <form className="space-y-6">
                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="query"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Query
                      </label>
                      <div className="mt-2">
                        <textarea
                          rows={4}
                          name="query"
                          id="query"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={query}
                          onChange={(e) => setQuery(e?.target?.value)}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="displayName"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Subscription
                      </label>
                      <div className="mt-2">
                        <select
                          id="status"
                          name="status"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={selectedSubscription}
                          required
                          onChange={(e) =>
                            setSelectedSubscription(e?.target?.value)
                          }
                        >
                          <option value={""} key={"option1"}>
                            ---Choose Subscription---
                          </option>
                          {(props?.subscriptionList || [])?.map(
                            (subscription) => (
                              <option
                                value={subscription.subscriptionId}
                                key={subscription.subscriptionId}
                              >
                                {subscription.subcriptionName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {!loading && resourceData?.length > 0 && (
                <div className="max-h-[500px] overflow-y-auto bg-white">
                  { selectedResource?.length > 0 &&
                    <div className="mt-2">
                      <button
                      type="button"
                      disabled={loading}
                      className="inline-fle justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={openCloudResourceModal}
                    >
                      Resource  
                    </button>
                    </div>
                  }
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 relative px-7 sm:w-12 sm:px-6"
                        >
                          <input
                            type="checkbox"
                            className="absolute border-gray-300 bg-white/75 backdrop-blur backdrop-filter left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            ref={checkbox}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 border-gray-300 bg-white/75 backdrop-blur backdrop-filter z-10 px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 border-gray-300 bg-white/75 backdrop-blur backdrop-filter z-10 px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                        >
                          Type
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {(resourceData || [])?.map((item) => (
                        <tr
                          key={item?.resourceId}
                          className={
                            selectedResource.includes(item)
                              ? "bg-gray-50"
                              : undefined
                          }
                        >
                          <td className="relative px-7 sm:w-12 sm:px-6">
                            {selectedResource.includes(item) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              value={item.id}
                              checked={selectedResource.includes(item)}
                              onChange={(e) =>
                                setSelectedResource(
                                  e.target.checked
                                    ? [...selectedResource, item]
                                    : selectedResource.filter((p) => p !== item)
                                )
                              }
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                            {item?.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                            {item?.type}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {!loading && codeText && (
                <>
                  <div className="mt-2 p-4">
                    <button
                      type="button"
                      disabled={loading}
                      className="inline-fle justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={download}
                    >
                      Download
                    </button>
                    <div className="my-6 max-h-[500px] overflow-y-auto">
                      <CodeGroup title="JSON" code={codeText} tag="">
                        <div>
                          <pre className="language-json">
                            {JSON.stringify(jsonData, null, 2)}
                          </pre>
                        </div>
                      </CodeGroup>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={loading}
            className={commonService.classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              loading ? "disabled:opacity-75" : ""
            )}
            onClick={executeKQLQuery}
          >
            <Spinner show={loading} />
            Run
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
    </>
  );
}
