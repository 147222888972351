import commonService from "../service/commonService";

export default function PrivacyPolicy() {
  const dbkProfile = commonService.dbkProfile;

  return (
    <>
      <div className="bg-white shadow">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="h-12 w-auto mt-4"
                  src={dbkProfile.imgUrl}
                  alt={dbkProfile.name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto px-8 py-8">
        <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
          Privacy Policy
        </h2>
        <p className="mt-2 text-base leading-7 text-gray-600">
         <span className="text-gray-900 font-semibold">Effective Date</span>: 01/07/2024
        </p>
        <p className="mt-2 text-base leading-7 text-gray-600">
        <span className="text-gray-900 font-semibold">Glass FinOps Platform</span> ("we," "our," or "the platform") is committed to
          protecting the privacy of our users ("you"). This Privacy Policy
          outlines how we collect, use, and protect your information when you
          use our cloud cost analysis platform.
        </p>

        <div className="mt-8">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-xl">
            1. Information We Collect
          </h2>
          <p className="mt-2 text-base leading-7 text-gray-600">
            We may collect the following types of information when you use our
            platform:
          </p>
          <dl className="mt-4">
            <div key="1" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Personal Information
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                Information such as your name, email address, company name, and
                login credentials required for account creation and
                authentication.
              </dd>
            </div>
            <div key="2" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Cloud Data
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                Cloud cost and usage data that you provide or that is retrieved
                from third-party cloud service providers, including but not
                limited to subscription information, resource allocation, and
                financial data.
              </dd>
            </div>
            <div key="3" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Technical Information
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                Information about the device and browser you use to access the
                platform, including IP address, device identifiers, operating
                system, and session data.
              </dd>
            </div>
            <div key="4" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Usage Data
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                Information about how you use the platform, such as page views,
                navigation patterns, and feature usage.
              </dd>
            </div>
          </dl>
        </div>

        <div className="mt-8">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-xl">
            2. How We Use Your Information
          </h2>
          <p className="mt-2 text-base leading-7 text-gray-600">
            We use the information we collect to:
          </p>
          <dl className="mt-4">
            <div key="5" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Provide and Maintain the Platform
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                Ensure smooth operation and delivery of the core services,
                including cloud cost analysis, financial optimization, and
                actionable recommendations.
              </dd>
            </div>
            <div key="6" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Personalize User Experience
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                Tailor recommendations, reports, and interactions based on your
                cloud usage patterns and preferences.
              </dd>
            </div>
            <div key="7" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Improve the Platform
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                Analyze usage trends and user feedback to enhance the
                functionality, performance, and security of the platform.
              </dd>
            </div>
            <div key="8" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Communicate with You
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                Send you notifications, updates, and information regarding your
                account, as well as respond to customer service requests and
                inquiries.
              </dd>
            </div>
            <div key="9" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Ensure Compliance
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                Monitor for fraudulent activities, ensure compliance with
                applicable laws, and meet our legal obligations.
              </dd>
            </div>
          </dl>
        </div>

        <div className="mt-8">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-xl">
            3. How We Share Your Information
          </h2>
          <p className="mt-2 text-base leading-7 text-gray-600">
            We do not sell or share your personal data with third parties for
            their own marketing purposes. However, we may share your data in the
            following situations:
          </p>
          <dl className="mt-4">
            <div key="10">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Third-Party Service Providers
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                We may share data with service providers who assist us in
                operating the platform, including cloud service integrations,
                data storage, and analytics.
              </dd>
            </div>
            <div key="11" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Legal Compliance
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                We may disclose your information to comply with applicable laws,
                regulations, legal processes, or government requests.
              </dd>
            </div>
            <div key="12" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Business Transactions
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                In the event of a merger, acquisition, or sale of all or part of
                our assets, your information may be transferred to the new
                entity as part of the transaction.
              </dd>
            </div>
          </dl>
        </div>

        <div className="mt-8">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-xl">
            4. Data Security
          </h2>
          <p className="mt-2 text-base leading-7 text-gray-600">
            We use industry-standard security measures, including encryption and
            access controls, to protect your data from unauthorized access,
            disclosure, or modification. However, no online service can be 100%
            secure, and we cannot guarantee the absolute security of your data.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-xl">
            5. Your Rights and Choices
          </h2>
          <dl className="mt-4">
            <div key="13" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Access and Correction
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                You can access and update your account information directly
                through the platform or by contacting us.
              </dd>
            </div>
            <div key="14" className="mb-2">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Data Deletion
              </dt>
              <dd className="text-base leading-7 text-gray-600">
                {" "}
                You may request the deletion of your personal information by
                contacting us, subject to certain legal obligations.
              </dd>
            </div>
          </dl>
        </div>

        <div className="mt-8">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-xl">
            6. Changes to This Policy
          </h2>
          <p className="mt-2 text-base leading-7 text-gray-600">
            We may update this Privacy Policy from time to time. When we do, we
            will post the updated version with a revised effective date. We
            encourage you to review this policy regularly to stay informed about
            our data practices.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-xl">
            7. Contact Us
          </h2>
          <p className="mt-2 text-base leading-7 text-gray-600">
            If you have any questions about this Privacy Policy or our data
            practices, please contact us at:<br />
            <span className="text-gray-900 font-semibold">Email</span>: <a href="mailto:legal@glassfinops.com" className="font-semibold text-indigo-600 hover:text-indigo-500">legal@glassfinops.com</a><br />
            <span className="text-gray-900 font-semibold">Address</span>: [Insert Address]
          </p>
        </div>
      </div>
    </>
  );
}
