import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { ModalWrapper } from "../../common/modalWrapper";
import { EmptyView } from "../../../app/common/commonControls";
interface Props {
  show: boolean;
  closeModal: () => void;
  protectedVmData: AzureResourceDetectProtectedVmItemDetail[];
}

export default function ProtectedVmModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6" 
      id="protected-vm-modal">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Protected VM
            </Dialog.Title>
            <div className="mt-2 overflow-x-auto">
              {(props?.protectedVmData || []).length > 0 ? (
                <table className="min-w-full divide-y divide-gray-300 mt-2">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                      >
                        Vault ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                      >
                        VM Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                      >
                        Resource Group Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                      >
                        Protection Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                      >
                        Last Backup Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {(props?.protectedVmData || [])?.map((item) => (
                      <tr key={item.vaultId}>
                        <td className="px-3 py-2 text-sm font-small text-gray-900">
                          <span className="max-w-[500px] break-all">{item?.vaultId}</span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                          {item?.vmName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                          {item?.rgName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                          {item?.protectionState || item?.isProtected === "Yes" ? 'Protected': 'Protection Stopped'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                          {new Date(item?.lastBackupTime as string).toLocaleDateString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <EmptyView />
              )}
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={closeModal}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
