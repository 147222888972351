import { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { CodeGroup, CodeText, Note } from "../../documents";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ModalWrapper } from "../../common/modalWrapper";
import CloudResourceService from "../../../app/service/cloudResourceService";
import { toast } from "react-toastify";
import Spinner from "../../../app/spinner/spinner";
interface Props {
  show: boolean;
  closeModal: (refresh?: boolean) => void;
  subscriptionList: SubscriptionItem[];
}
export default function DelegatedReaderAccessDocumentModal(
  props: Readonly<Props>
) {
  const [open, setOpen] = useState(props.show);
  const currentUri = `https://${process.env.REACT_APP_API_DOMAIN}/ref/lighthouse/lighthouse-ff-general-reader.json`;
  const [uri, setUri] = useState<string>(currentUri);

  const [codeText, setCodeText] = useState<string>("");

  const cancelButtonRef = useRef(null);

  const [regions, setRegions] = useState<any>([]);
  const [loadingRegion, setLoadingRegion] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<string>('');

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      getRegions('00000000-0000-0000-0000-000000000000');
      setCode('');
    }
  }, [props?.show]);

  const setCode = (region: string) => {
    let code = "";
    if ((props.subscriptionList || []).length > 0) {
      (props.subscriptionList || []).forEach((subscription) => {
        code += `az account set --subscription ${subscription.subscriptionId}\n\naz deployment sub create --name Float-FinOps-Analyst --location ${region || '{LOCATION}' } --template-uri ${currentUri} --verbose\n\n`;
      });
    } else {
      code = `az account set --subscription \n\naz deployment sub create --name Float-FinOps-Analyst --location ${region || '{LOCATION}' } --template-uri ${currentUri} --verbose`;
    }
    setCodeText(code);
  }

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const getRegions = (id: string) => {
    setLoadingRegion(true);
    CloudResourceService.listAvailableRegionsBySubscription(id)
      .then((response: any) => {
        setLoadingRegion(false);
        const result = response?.data?.result?.value || [];
        setRegions(result);
      })
      .catch((e: any) => {
        setLoadingRegion(false);
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const onChangeRegion = (region: string) => {
    setSelectedRegion(region);
    setCode(region);
  }

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Delegated Reader Access
            </Dialog.Title>
            <div className="mt-2 p-4">
              <div className="mt-4 mb-2">
                <Note type="info">
                  This deployment must be done by a non-guest account in the
                  customer's tenant who has a role with the 
                  <CodeText>
                    Microsoft.Authorization/roleAssignments/write
                  </CodeText>
                   permission, such as Owner, for the subscription being
                  onboarded
                </Note>
                <p className="text-sm text-gray-700 mt-8">
                  This will grant Float FinOps, delegated reader access for our
                  finops analyst to go into the system and perform the
                  evaluation task to provide recommendations. This also allows
                  our app to get inventory and usage metrics at the control
                  plane level.
                </p>
              </div>

              <div className="my-6">
                <h3>Option A:</h3>
                <p className="text-sm text-gray-700 mt-1 mb-3">
                  Run the following code on the CLI
                </p>
                <div className="mb-4">
                    <label
                      htmlFor="subscription"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Location
                    </label>
                    <div className="mt-2">
                      {
                        loadingRegion ?
                        <div className="leading-6 h-9">
                        <Spinner show={loadingRegion} isPrimary={true} />
                        </div>:
                        <select
                        id="Location"
                        name="Location"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={selectedRegion}
                        required
                        onChange={(e) =>
                          onChangeRegion(e?.target?.value)
                        }
                      >
                        <option value={""} key={"option2"}>
                          ---Choose Location---
                        </option>
                        {(regions || [])?.map((region: any) => (
                          <option
                            value={region.name}
                            key={region.name}
                          >
                            {region.displayName}
                          </option>
                        ))}
                      </select>
                      }
                      
                    </div>
                  </div>
                <CodeGroup title="Azure CLI" code={codeText} tag="">
                  <span>
                    {(props?.subscriptionList || []).length > 0 ? (
                      <>
                        {(props?.subscriptionList || [])?.map(
                          (subscription) => (
                            <>
                              <p>{`az account set --subscription ${subscription.subscriptionId}`}</p>
                              <br />
                              <p>
                                {`az deployment sub create --name Float-FinOps-Analyst --location ${selectedRegion || '{LOCATION}' } --template-uri ${uri} --verbose`}
                              </p>
                              <br />
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        <p>{`az account set --subscription `}</p>
                        <br />
                        <p>
                          {`az deployment sub create --name Float-FinOps-Analyst --location ${selectedRegion || '{LOCATION}' } --template-uri ${uri} --verbose`}
                        </p>
                        <br />
                      </>
                    )}
                  </span>
                </CodeGroup>
              </div>

              <div className="my-6">
                <h3>Option B:</h3>
                <p className="text-sm text-gray-700 mt-1 mb-3">
                  Use the Deploy to Azure btn
                </p>
                <Note type="info">
                  Ensure to run this on every subscription you want included in
                  the Analysis
                </Note>
                <a
                  href="https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fglass-dev.dbk.cloud%2Fref%2Ffloatfinops-lighthouse.json"
                  target="_blank"
                >
                  <img
                    src="https://aka.ms/deploytoazurebutton"
                    alt="azure btn"
                  />
                </a>
              </div>

              <div>
                <h3>Additional Documentation:</h3>
                <p className="text-sm text-gray-700">
                  <a
                    className="underline flex items-center"
                    href="https://learn.microsoft.com/en-us/cli/azure/"
                    target={"_blank"}
                  >
                    <InformationCircleIcon
                      aria-hidden="true"
                      className="w-4 h-4"
                    />
                    Azure CLI
                  </a>
                </p>
                <p className="text-sm text-gray-700">
                  <a
                    href="https://learn.microsoft.com/en-us/azure/lighthouse/how-to/onboard-customer#deploy-the-azure-resource-manager-template"
                    className="underline flex items-center"
                    target={"_blank"}
                  >
                    <InformationCircleIcon
                      aria-hidden="true"
                      className="w-4 h-4"
                    />
                    Azure Lighthouse
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
