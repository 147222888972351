import {
  CommandLineIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { UserGroupIcon, UserIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Pulse from "../../app/pulse/pulse";
import CloudResourceService from "../../app/service/cloudResourceService";
import commonService from "../../app/service/commonService";
import KQLQueryService from "../../app/service/kqlQueryService";
import OpportunityService from "../../app/service/opportunityService";
import KqlQueryExecuteModal from "../initiatives/modal/kqlQueryExecuteModal";
import KqlQueryModal from "../initiatives/modal/kqlQueryModal";
import ConfirmationModal from "../reports/modal/confirmationModal";

const deleteQueryModal = {
  title: "Delete Query",
  message: "Are you sure you want to delete the query",
};

export default function DynamicQuery() {
  const [loadingQuery, setLoadingQuery] = useState<boolean>(false);
  const [allQueries, setAllQueries] = useState<KQLQueryItem[]>([]);
  const [showKqlQueryModal, setShowKqlQueryModal] = useState<boolean>(false);
  const [selectedQuery, setSelectedQuery] = useState<KQLQueryItem>();
  const [isEditQuery, setIsEditQuery] = useState<boolean>(false);
  const [showDeleteQueryModal, setShowDeleteQueryModal] =
    useState<boolean>(false);
  const [deleteQueryMessage, setDeleteQueryMessage] = useState<string>("");
  const [selectedQueryForDelete, setSelectedQueryForDelete] =
    useState<string>("");
  const [loadingDeleteQuery, setLoadingDeleteQuery] = useState<boolean>(false);
  const [showQueryExecuteModal, setShowQueryExecuteModal] =
    useState<boolean>(false);

  const [subscriptionList, setSubscriptionList] = useState<SubscriptionItem[]>(
    []
  );

  const [templates, setTemplates] = useState<
    OpportunityPlanResourceTaskTemplateItem[]
  >([]);

  useEffect(() => {
    getAllSubscriptions();
    getAllKqlQueries();
    getPlanResourceTaskTemplate();
  }, []);

  const getAllSubscriptions = () => {
    CloudResourceService.getAllSubscriptions()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setSubscriptionList(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const getAllKqlQueries = () => {
    setLoadingQuery(true);
    KQLQueryService.getAllKqlQueries()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setAllQueries(result);
        setLoadingQuery(false);
      })
      .catch((e: any) => {
        setLoadingQuery(false);
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const openKqlQueryModal = (query?: KQLQueryItem) => {
    setShowKqlQueryModal(true);
    setSelectedQuery(query);
    if (query) {
      setIsEditQuery(true);
    } else {
      setIsEditQuery(false);
    }
  };

  const closeKqlQueryModal = (refresh?: boolean) => {
    setShowKqlQueryModal(false);
    if (refresh) {
      getAllKqlQueries();
    }
  };

  const closeDeleteQueryModal = (refresh?: boolean) => {
    setShowDeleteQueryModal(false);
    if (refresh) {
      getAllKqlQueries();
    }
  };

  const openDeleteQueryModal = (e: any, query: KQLQueryItem) => {
    e?.preventDefault();
    setDeleteQueryMessage(
      `${deleteQueryModal.message}: ${query.metadata.name} ?`
    );
    setSelectedQueryForDelete(query.metadata.name);
    setShowDeleteQueryModal(true);
  };

  const deleteKqlQuery = () => {
    setLoadingDeleteQuery(true);
    KQLQueryService.deleteKqlQuery(selectedQueryForDelete)
      .then((response: any) => {
        setLoadingDeleteQuery(false);
        commonService.showMessage(response?.data || {});
        if (response?.data?.isSuccess) {
          closeDeleteQueryModal(true);
        }
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoadingDeleteQuery(false);
      });
  };

  const openQueryExecuteModal = (query: KQLQueryItem) => {
    setSelectedQuery(query);
    setShowQueryExecuteModal(true);
  };

  const closeQueryExecuteModal = () => {
    setShowQueryExecuteModal(false);
  };

  const getPlanResourceTaskTemplate = () => {
    setTemplates([]);
    OpportunityService.getPlanResourceTaskTemplate()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setTemplates(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  return (
    <>
      {showKqlQueryModal && (
        <KqlQueryModal
          show={showKqlQueryModal}
          query={selectedQuery}
          isEdit={isEditQuery}
          closeModal={closeKqlQueryModal}
        />
      )}
      {showDeleteQueryModal && (
        <ConfirmationModal
          show={showDeleteQueryModal}
          title={deleteQueryModal.title}
          message={deleteQueryMessage}
          loading={loadingDeleteQuery}
          closeModal={() => closeDeleteQueryModal()}
          save={() => deleteKqlQuery()}
        />
      )}
      {showQueryExecuteModal && (
        <KqlQueryExecuteModal
          show={showQueryExecuteModal}
          query={selectedQuery}
          subscriptionList={subscriptionList}
          closeModal={closeQueryExecuteModal}
          templates={templates || []}
        />
      )}
      <div className="py-10">
        <header>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              Dynamic Query
            </h1>
          </div>
        </header>
      </div>
      <div className="bg-white px-6">
        <main>
          {loadingQuery ? (
            <Pulse show={loadingQuery} />
          ) : (
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                  >
                    Plan Compatible
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {(allQueries || [])?.map((item, i: number) => (
                  <tr key={item?.metadata?.name}>
                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                      <div className="flex items-center">
                        {item?.metadata?.displayName}
                        <span
                          className="ml-2"
                          data-tooltip-id={"-isPublic-tooltip" + i}
                        >
                          {item.metadata?.isPublic ? (
                            <UserGroupIcon
                              aria-hidden="true"
                              className="h-4 w-4"
                            />
                          ) : (
                            <UserIcon aria-hidden="true" className="h-4 w-4" />
                          )}
                          <Tooltip
                            id={"-isPublic-tooltip" + i}
                            arrowColor="transparent"
                            place="top"
                          >
                            <div style={{}}>
                              { item?.metadata?.isPublic ? 'Public Query': 'Private Query' }
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                      {item?.metadata?.description}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                      {item?.metadata?.isPlanCompatible ? "Yes" : "No"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                      <button
                        type="button"
                        className="mr-2 rounded-full p-1 bg-indigo-600 h-6 w-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => openQueryExecuteModal(item)}
                      >
                        <CommandLineIcon aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="mr-2 rounded-full p-1 bg-indigo-600 h-6 w-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => openKqlQueryModal(item)}
                      >
                        <PencilIcon aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="rounded-full p-1 bg-red-600 h-6 w-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        onClick={(e) => openDeleteQueryModal(e, item)}
                      >
                        <XMarkIcon aria-hidden="true" />
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={4}
                    className="relative whitespace-nowrap py-5 px-3 text-sm font-medium sm:pr-0"
                  >
                    <button
                      type="button"
                      className="w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => openKqlQueryModal()}
                    >
                      Add Query
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </main>
      </div>
    </>
  );
}
