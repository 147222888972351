import { ChangeEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import AuthenticationService from "../service/authService";
import commonService from "../service/commonService";
import UpdateRoleModal from "../../components/reports/modal/updateRoleModal";
import ConfirmationModal from "../../components/reports/modal/confirmationModal";
import Spinner from "../spinner/spinner";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import InvoiceModal from "../../components/initiatives/modal/invoiceModal";
import ReportService from "../service/reportService";
import Pulse from "../pulse/pulse";
import InvoiceStatusTable from "../../components/reports/invoiceStatusTable";
import InvoiceImportModal from "../../components/initiatives/modal/invoiceImportModal";
import DelegatedReaderAccessDocumentModal from "../../components/initiatives/modal/delegatedReaderAccessDocumentModal";
import CloudResourceService from "../service/cloudResourceService";
import { SecondaryNavigationHeader } from "../common/commonControls";
import InviteUserModal from "../../components/initiatives/modal/inviteUserModal";
import LinkPartnerIdModal from "../../components/initiatives/modal/linkPartnerIdModal";
import GovernanceOnboardingModal from "../../components/initiatives/modal/governanceOnboardingModal";

const secondaryNavigation = [
  { id: 1, name: "User Profile", href: "#" },
  { id: 2,name: "Account", href: "#" },
  { id: 3,name: "Team", href: "#" },
  { id: 4,name: "Invoice Status", href: "#" }
];

const errorState: any = {
  firstName: { hasError: false, required: true },
  lastName: { hasError: false, required: true },
  username: { hasError: false, required: true },
  email: { hasError: false, required: true },
};

const passwordErrorState: any = {
  currentPassword: { hasError: false },
  newPassword: { hasError: false },
  confirmPassword: { hasError: false },
};

const userModel: UpdateUser = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  phone: "",
  timeZone: "",
};

const passwordModel: UpdatePassword = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const accountModel: AccountItem = {
  id: "",
  parentId: "",
  name: "",
  website: "",
  size: "",
  mATenantId: "",
  isActive: false,
  users: [],
};

const accountErrorState: any = {
  name: { hasError: false, required: true },
  website: { hasError: false, required: true },
  size: { hasError: false, required: true },
};

const deleteAccountModal = {
  title: 'Delete Account',
  message: 'Are you sure you want to delete your account?'
}

const statuses: Record<string, string> = {
  Green: 'text-green-400 bg-green-400/10',
  Gray: 'text-gray-500 bg-gray-100/10'
};

export default function Settings() {
  const [currentMenuSelection, setCurrentMenuSelection] = useState<number>(secondaryNavigation[0]?.id);
  const [userData, setUserData] = useState<UpdateUser | any>(userModel);
  const [profileImage, setProfileImage] = useState<string>("");

  const [error, setError] = useState<any>(JSON.parse(JSON.stringify(errorState)));
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [passwordError, setPasswordError] = useState<any>(passwordErrorState);
  const [passwordData, setPasswordData] = useState<UpdatePassword | any>(
    passwordModel
  );
  const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);

  const [accountError, setAccountError] = useState<any>(accountErrorState);
  const [accountData, setAccountData] = useState<AccountItem | any>(
    accountModel
  );
  const [hasAccountError, setHasAccountError] = useState<boolean>(false);

  const [users, setUsers] = useState<User[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<User[]>([]);

  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [showInviteUserModal, setShowInviteUserModal] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [currentUserDetail, setCurrentUserDetail] = useState<User>();

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState<boolean>(false);
  const [loadingDeleteAccount, setLoadingDeleteAccount] = useState<boolean>(false);

  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);
  const [loadingInvoiceStatus, setLoadingInvoiceStatus] = useState<boolean>(false);
  const [invoiceStatusResult, setInvoiceStatusResult] = useState<InvoiceStatusResult[]>([]);

  const [showDelegatedReaderAccessModal, setShowDelegatedReaderAccessModal] = useState<boolean>(false);

  const [showLinkPartnerIdModal, setShowLinkPartnerIdModal] = useState<boolean>(false);
  const [showGovernanceOnboardingModal, setShowGovernanceOnboardingModal] = useState<boolean>(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentUser") ?? "{}") || {};
    setCurrentUser(user);
    const userDetail = JSON.parse(localStorage.getItem("userDetail") ?? "{}") || {};
    setCurrentUserDetail(userDetail);

    getUserProfile(user);
    getUserAccount();

    getUserImage();
    getInvoiceStatus();
    
    getAllSubscriptions();
  }, []);

  useEffect(() => {
    checkError();
  }, [userData]);

  useEffect(() => {
    checkPasswordError();
  }, [passwordData, passwordError]);

  useEffect(() => {
    checkAccountError();
  }, [accountData]);

  const getUserProfile = (user: any) => {
    AuthenticationService.getUserById(user?.id).then((response: any) => {
      const result = response?.data?.result || {};
      setUserData({
        firstName: result?.firstName,
        lastName: result?.lastName,
        username: result?.username,
        email: result?.email,
        phone: result?.phone,
        timeZone: result?.timeZone,
      });
    });
  };

  const getUserAccount = () => {
    AuthenticationService.getAccountById(commonService.getAccountId()).then(
      (response: any) => {
        const result = response?.data?.result || {};
        setAccountData(result);
        setUsers(result?.users || []);
      }
    );
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<any>();

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setFile(e.target.files[0]);
  };

  const updateImage = () => {
    const formData = new FormData();
    formData.append("file", file);

    AuthenticationService.updateImage(formData).then((response: any) => {
      if (response?.data?.result) {
        getUserImage();
      }
    });
  };

  const onUpdateAccount = (e?: any) => {
    e?.preventDefault();
    setLoading(true);
    AuthenticationService.updateAccount(accountData)
      .then((response: any) => {
        commonService.showMessage(response?.data || {});
        setLoading(false);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const getUserImage = () => {
    AuthenticationService.getProfileImage().then((response: any) => {
      setProfileImage(response?.data?.result || "");
      localStorage.setItem("profileImage", response?.data?.result || "");
    });
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(errorState) || []) {
      if (errorState[key]?.required && (!userData[key] || (key==='email' && !commonService.isValidEmail(userData[key])))) {
        isError = true;
      }
    }
    setHasError(isError);
  };

  const checkPasswordError = () => {
    let isError = false;
    for (const key of Object.keys(passwordErrorState) || []) {
      if (!commonService.passwordPattern?.test(passwordData[key])) {
        isError = true;
      }
      if (
        key === "confirmPassword" &&
        passwordData?.newPassword !== passwordData?.confirmPassword
      ) {
        isError = true;
      }
    }
    setHasPasswordError(isError);
  };

  const onPasswordInputChange = (fieldName: string, value: string) => {
    const field = passwordError[fieldName] || {};
    const model = JSON.parse(JSON.stringify(passwordData));
    model[fieldName] = value;
    setPasswordData(model);
    if (fieldName === "confirmPassword") {
      field.hasError = !value || model?.newPassword !== model?.confirmPassword;
    } else {
      field.hasError = !commonService.passwordPattern?.test(value);
    }
    passwordError[fieldName] = field;
  };

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    if(fieldName === 'email') {
      field.hasError = !value || !commonService.isValidEmail(value);
    } else {
      field.hasError = !value;
    }
    const model = JSON.parse(JSON.stringify(userData));
    model[fieldName] = value;
    setUserData(model);
    error[fieldName] = field;
  };

  const onAccountInputChange = (fieldName: string, value: string) => {
    const field = accountError[fieldName] || {};
    field.hasError = !value;
    const model = JSON.parse(JSON.stringify(accountData));
    model[fieldName] = value;
    setAccountData(model);
    accountError[fieldName] = field;
    setAccountError(error);
  };

  const checkAccountError = () => {
    let isError = false;
    for (const key of Object.keys(accountErrorState) || []) {
      if (accountErrorState[key]?.required && !accountData[key]) {
        isError = true;
      }
    }
    setHasAccountError(isError);
  };

  const onUpdateUserData = (e?: any) => {
    e?.preventDefault();
    setLoading(true);
    AuthenticationService.updateUserProfile(userData)
      .then((response: any) => {
        commonService.showMessage(response?.data || {});
        setLoading(false);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const onUpdatePassword = (e?: any) => {
    e?.preventDefault();
    setLoading(true);
    const requestBody: UpdatePasswordRequest = {
      CurrentPassword: passwordData?.currentPassword,
      NewPassword: passwordData?.newPassword,
    };
    AuthenticationService.updatePassword(requestBody)
      .then((response: any) => {
        commonService.showMessage(response?.data || {});
        setLoading(false);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const [showUserRoleManagementModal, setShowUserRoleManagementModal] = useState(false)
  const [selectedUserDetail, setSelectedUserDetail] = useState<User>();

  const openRoleManagementModal = (person: User) => {
    setShowUserRoleManagementModal(true);
    setSelectedUserDetail(person);
  }

  const closeRoleManagementModal = () => {
    setShowUserRoleManagementModal(false);
    getUserAccount();
  }

  const closeDeleteAccountModal = () => {
    setShowDeleteAccountModal(false);
  }

  const deleteAccount = (e?: any) => {
    e?.preventDefault();
    setShowDeleteAccountModal(true);
  }

  const onDeleteAccount = () => {
    setLoadingDeleteAccount(true);
    AuthenticationService.deleteAccount(accountData?.id)
      .then((response: any) => {
        commonService.showMessage(response?.data || {});
        setLoadingDeleteAccount(false);
        closeDeleteAccountModal();
        setAccountData({});
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoadingDeleteAccount(false);
      });
  };

  const onSelectCSPAction = (openAction: () => void) => {
    if(openAction) {
      openAction();
    }
  }

  const openInvoiceModal = () => {
    setShowInvoiceModal(true);
}

const closeInvoiceModal = () => {
    setShowInvoiceModal(false);
};

  const getInvoiceStatus = () => {
    setLoadingInvoiceStatus(true);
    ReportService.getInvoiceStatus()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setInvoiceStatusResult(result);
        setLoadingInvoiceStatus(false);
      })
      .catch((e: any) => {
        setLoadingInvoiceStatus(false);
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const refreshDetails = (refresh?: boolean) => {
    if (refresh) {
      getInvoiceStatus();
    }
  };

  const openInvoiceImportModal = () => {
    setShowImportModal(true);
  };

  const closeInvoiceImportModal = () => {
    setShowImportModal(false);
  };

  const openDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(true);
  };

  const closeDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(false);
  };

  const openInviteUserModal = () => {
    setShowInviteUserModal(true);
  };

  const closeInviteuserModal = () => {
    setShowInviteUserModal(false);
  };

  const openLinkPartnerIdModal = () => {
    setShowLinkPartnerIdModal(true);
  };

  const closeLinkPartnerIdModal = () => {
    setShowLinkPartnerIdModal(false);
  };

  const openGovernanceOnboardingModal = () => {
    setShowGovernanceOnboardingModal(true);
  };

  const closeGovernanceOnboardingModal = () => {
    setShowGovernanceOnboardingModal(false);
  };

  const cspDefaultList: Array<{id: number, name: string, description: string, indicator: string, openModal: () => void}> = [
    {
      id: 1,
      name: 'Initial InvoiceImport',
      description: 'Pre-sale invoice evaluation process',
      indicator: 'Gray',
      openModal: openInvoiceModal
    },
    {
      id: 2,
      name: 'Invoice Focus Export',
      description: 'Every month Import the Azure Invoice',
      indicator: 'Gray',
      openModal: openInvoiceImportModal
    },
    {
      id: 3,
      name: 'Delegated Reader Access',
      description: 'Allow delegated reader access for our analyst, review the usage and cost optimization',
      indicator: 'Gray',
      openModal: openDelegatedReaderAccessModal
    },
    {
      id: 4,
      name: 'Link Partner ID',
      description: 'Link Partner ID workflow.',
      indicator: 'Gray',
      openModal: openLinkPartnerIdModal
    },
    {
      id: 5,
      name: 'Delegated Managed Service Access',
      description: 'Allow delegated contributor access for our cloud engineers to perform the approved',
      indicator: 'Gray',
      openModal: () => {}
    },
    {
      id: 6,
      name: 'Enterprise Application Access',
      description: 'Allow our automation account the access needed to monitor the subscription.',
      indicator: 'Gray',
      openModal: () => {}
    },
    {
      id: 7,
      name: 'Governance Onboarding',
      description: 'Governance Onboarding.',
      indicator: 'Gray',
      openModal: openGovernanceOnboardingModal
    }
  ];

  const [subscriptionList, setSubscriptionList] = useState<SubscriptionItem[]>([]);
  const [cspList, setCspList] = useState<Array<{id: number, name: string, description: string, indicator: string, openModal: () => void}>>(cspDefaultList);
  const [selectedSubscription, setSelectedSubscription] = useState<string>('');

  const getAllSubscriptions = () => {
    CloudResourceService.getAllSubscriptions()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setSubscriptionList(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const onSelectSubscription = (subscription: string) => {
    setSelectedSubscription(subscription);
    const roles: string[] =
      subscriptionList.find(
        (x: SubscriptionItem) => x.subscriptionId === subscription
      )?.rolesName || [];
    const list = cspDefaultList;
    if (roles.indexOf("Billing Reader") > -1) {
      list[2].indicator = "Green";
    }
    if (roles.indexOf("Contributor") > -1) {
      list[4].indicator = "Green";
    }
    setCspList(list);
  };

  const getFileName = () => {
    return file ? `${file.name}` : "Change avatar"
  }

  const isSubmitBtnDisable = () => {
    return hasError || loading;
  }

  const isInvitationBtnDisable = () => {
    return hasError || loading;
  }

  const isPasswordBtnDisable = () => {
    return hasPasswordError || loading;
  }

  const isAccountBtnDisable = () => {
    return hasAccountError || loading;
  }

  return (
    <>
      <ConfirmationModal
        show={showDeleteAccountModal}
        title={deleteAccountModal.title}
        message={deleteAccountModal.message}
        loading={loadingDeleteAccount}
        closeModal={() => closeDeleteAccountModal()}
        save={() => onDeleteAccount()}
      />
      <UpdateRoleModal
        show={showUserRoleManagementModal}
        data={selectedUserDetail as User}
        closeModal={closeRoleManagementModal}
      />
      <InvoiceModal
        show={showInvoiceModal}
        closeModal={() => closeInvoiceModal()}
        refreshDetails={refreshDetails}
      />
      <InvoiceImportModal
        show={showImportModal}
        closeModal={() => closeInvoiceImportModal()}
        subscriptionList={subscriptionList}
      />
      <DelegatedReaderAccessDocumentModal
        show={showDelegatedReaderAccessModal}
        closeModal={() => closeDelegatedReaderAccessModal()}
        subscriptionList={subscriptionList}
      />
      <InviteUserModal
        show={showInviteUserModal}
        closeModal={() => closeInviteuserModal()}
      />
      {showLinkPartnerIdModal && <LinkPartnerIdModal
        show={showLinkPartnerIdModal}
        closeModal={() => closeLinkPartnerIdModal()}
      />}
      {showGovernanceOnboardingModal && <GovernanceOnboardingModal
        show={showGovernanceOnboardingModal}
        closeModal={() => closeGovernanceOnboardingModal()}
      />}
      <div>
        {/* Static sidebar for desktop */}

        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <main>
          <SecondaryNavigationHeader navigation={secondaryNavigation} currentMenuSelection={currentMenuSelection} setCurrentMenuSelection={setCurrentMenuSelection} />

            {/* Settings forms */}
            {currentMenuSelection === 1 && (
              <div className="divide-y divide-white/5">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-500">
                      Personal Information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-500">
                      Use a permanent address where you can receive mail.
                    </p>
                  </div>

                  <form className="md:col-span-2" onSubmit={onUpdateUserData}>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                      <div className="col-span-full flex items-center gap-x-8">
                        <img
                          src={profileImage}
                          alt=""
                          className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                        />
                        <div>
                          <button
                            type="button"
                            className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={handleUploadClick}
                          >
                            {getFileName()}
                          </button>
                          <p className="mt-2 text-xs leading-5 text-gray-500">
                            JPG, GIF or PNG. 1MB max.
                          </p>
                          <input
                            type="file"
                            ref={inputRef}
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                          {file && (
                            <button
                              type="button"
                              className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={updateImage}
                            >
                              Save Image
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          First name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            value={userData.firstName}
                            onChange={(e) =>
                              onInputChange("firstName", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(error, 'firstName')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {commonService.hasFieldError(error, 'firstName') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          First Name is required.
                        </p>
                        )}
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Last name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            value={userData.lastName}
                            onChange={(e) =>
                              onInputChange("lastName", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(error, 'lastName')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {commonService.hasFieldError(error, 'lastName') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Last Name is required.
                        </p>
                        )}
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={userData.email}
                            onChange={(e) =>
                              onInputChange("email", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(error, 'email')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {commonService.hasFieldError(error, 'email') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          {!userData.email
                            ? "Email is required."
                            : "Invalid Email"}
                        </p>
                        )}
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Username
                        </label>
                        <div className="mt-2">
                          <input
                            id="username"
                            name="username"
                            type="text"
                            autoComplete="username"
                            value={userData.username}
                            onChange={(e) =>
                              onInputChange("username", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(error, 'username')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {commonService.hasFieldError(error, 'username') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Username is required.
                        </p>
                        )}
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="timezone"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Timezone
                        </label>
                        <div className="mt-2">
                          <select
                            id="timezone"
                            name="timezone"
                            value={userData.timeZone}
                            onChange={(e) =>
                              onInputChange("timeZone", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            )}
                          >
                            <option>Pacific Standard Time</option>
                            <option>Eastern Standard Time</option>
                            <option>Greenwich Mean Time</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Phone
                        </label>
                        <div className="mt-2">
                          <input
                            id="phone"
                            name="phone"
                            type="text"
                            value={userData.phone}
                            onChange={(e) =>
                              onInputChange("phone", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-8 flex">
                      <button
                        type="submit"
                        disabled={isSubmitBtnDisable()}
                        className={commonService.classNames(
                          "flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                          isSubmitBtnDisable() ? "disabled:opacity-75" : ""
                        )}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>

                <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-500">
                      Change password
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      Update your password associated with your account.
                    </p>
                  </div>

                  <form className="md:col-span-2" onSubmit={onUpdatePassword}>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                      <div className="col-span-full">
                        <label
                          htmlFor="current-password"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Current password
                        </label>
                        <div className="mt-2">
                          <input
                            id="current-password"
                            name="current_password"
                            type="password"
                            autoComplete="current-password"
                            value={passwordData.currentPassword}
                            onChange={(e) =>
                              onPasswordInputChange(
                                "currentPassword",
                                e?.target?.value
                              )
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(passwordError, 'currentPassword')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {commonService.hasFieldError(passwordError, 'currentPassword') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Has minimum 8 characters, At least one uppercase,
                          one lowercase, one digit, one special character.
                        </p>
                        )}
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="new-password"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          New password
                        </label>
                        <div className="mt-2">
                          <input
                            id="new-password"
                            name="new_password"
                            type="password"
                            autoComplete="new-password"
                            value={passwordData.newPassword}
                            onChange={(e) =>
                              onPasswordInputChange(
                                "newPassword",
                                e?.target?.value
                              )
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(passwordError, 'newPassword')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {commonService.hasFieldError(passwordError, 'newPassword') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Has minimum 8 characters, At least one uppercase,
                          one lowercase, one digit, one special character.
                        </p>
                        )}
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="confirm-password"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Confirm password
                        </label>
                        <div className="mt-2">
                          <input
                            id="confirm-password"
                            name="confirm_password"
                            type="password"
                            autoComplete="new-password"
                            value={passwordData.confirmPassword}
                            onChange={(e) =>
                              onPasswordInputChange(
                                "confirmPassword",
                                e?.target?.value
                              )
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(passwordError, 'confirmPassword')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {commonService.hasFieldError(passwordError, 'confirmPassword') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Password Mismatched.
                        </p>
                        )}
                      </div>
                    </div>

                    <div className="mt-8 flex">
                      <button
                        type="submit"
                        disabled={isPasswordBtnDisable()}
                        className={commonService.classNames(
                          "flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                          isPasswordBtnDisable()
                            ? "disabled:opacity-75"
                            : ""
                        )}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>

                <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-500">
                      Delete account
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      No longer want to use our service? You can delete your
                      account here. This action is not reversible. All
                      information related to this account will be deleted
                      permanently.
                    </p>
                  </div>

                  <form
                    className="flex items-start md:col-span-2"
                    onSubmit={deleteAccount}
                  >
                    <button
                      type="submit"
                      disabled={loadingDeleteAccount}
                      className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                    >
                      <Spinner show={loadingDeleteAccount} />
                      Yes, delete my account
                    </button>
                  </form>
                </div>
              </div>
            )}

            {currentMenuSelection === 2 && (
              <div className="divide-y divide-white/5">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-500">
                      Account Information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-500">
                      Use a permanent address where you can receive mail.
                    </p>
                  </div>

                  <form className="md:col-span-2" onSubmit={onUpdateAccount}>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                      <div className="col-span-full">
                        <label
                          htmlFor="account-name"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Name
                        </label>
                        <div className="mt-2">
                          <input
                            id="account-name"
                            name="account_name"
                            type="text"
                            value={accountData.name}
                            onChange={(e) =>
                              onAccountInputChange("name", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(accountError, 'name')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {commonService.hasFieldError(accountError, 'name') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Name is required.
                        </p>
                        )}
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="account-website"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Website
                        </label>
                        <div className="mt-2">
                          <input
                            id="account-website"
                            name="account_website"
                            type="text"
                            value={accountData.website}
                            onChange={(e) =>
                              onAccountInputChange("website", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(accountError, 'website')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {commonService.hasFieldError(accountError, 'website') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Website is required.
                        </p>
                        )}
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="account-size"
                          className="block text-sm font-medium leading-6 text-gray-500"
                        >
                          Size
                        </label>
                        <div className="mt-2">
                          <select
                            id="account-size"
                            name="account_size"
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(accountError, 'size')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                            value={accountData.size}
                            required
                            onChange={(e) =>
                              onAccountInputChange("size", e?.target?.value)
                            }
                          >
                            {commonService.companySizeList?.map(
                              (size: { name: string; value: string }) => (
                                <option value={size.value} key={size.value}>
                                  {size.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {commonService.hasFieldError(accountError, 'size') && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Size is required.
                        </p>
                        )}
                      </div>
                    </div>

                    <div className="mt-8 flex">
                      <button
                        type="submit"
                        disabled={isAccountBtnDisable()}
                        className={commonService.classNames(
                          "flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                          isAccountBtnDisable()
                            ? "disabled:opacity-75"
                            : ""
                        )}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>

                <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-500">
                      CSP
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-500">
                    Connector for Microsoft Azure.
                    </p>
                  </div>

                  <div className=" md:col-span-2">

                  <div className="mt-2">
                          <select
                            id="account-size"
                            name="account_size"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={selectedSubscription}
                            required
                            onChange={(e) => onSelectSubscription(e?.target?.value)}
                          >
                            <option value={""} key={"option1"}>
                                            ---Choose Subscription---
                                          </option>
                            {(subscriptionList || [])?.map(
                              (subscription) => (
                                <option value={subscription.subscriptionId} key={subscription.subscriptionId}>
                                  {subscription.subcriptionName}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                  <div className="border rounded-md border-gray-200 p-4 mt-4">
                    <ul className="divide-y divide-black/5">
                      {cspList.map((csp) => (
                        <li
                          key={csp.id}
                          className="relative flex items-center space-x-4 py-4"
                        >
                          <div className="min-w-0 flex-auto">
                            <div className="flex items-center gap-x-3">
                              <div
                                className={commonService.classNames(
                                  statuses[csp.indicator],
                                  "flex-none rounded-full p-1"
                                )}
                              >
                                <div className="h-2 w-2 rounded-full bg-current" />
                              </div>
                              <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                                <a
                                  className="flex gap-x-2 cursor-pointer"
                                  onClick={() => onSelectCSPAction(csp.openModal)}
                                >
                                  <span className="truncate text-gray-500">
                                    {csp.name}
                                  </span>
                                  {/* <span className="text-gray-400">/</span>
                                  <span className="whitespace-nowrap">
                                    {deployment.projectName}
                                  </span> */}
                                  <span className="absolute inset-0" />
                                </a>
                              </h2>
                            </div>
                            <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-500">
                              <p className="truncate">
                                {csp.description}
                              </p>
                              <svg
                                viewBox="0 0 2 2"
                                className="h-0.5 w-0.5 flex-none fill-gray-300"
                              >
                                <circle cx={1} cy={1} r={1} />
                              </svg>
                              {/* <p className="whitespace-nowrap">
                                open
                              </p> */}
                            </div>
                          </div>
                          <div
                            className={commonService.classNames(
                              'text-gray-400 bg-gray-400/10 ring-gray-400/20',
                              "rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset"
                            )}
                          >
                            Open
                          </div>
                          <ChevronRightIcon
                            className="h-5 w-5 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                  </div>
                </div>
              </div>
            )}

            {currentMenuSelection === 3 && (
              <div className="divide-y divide-white/5">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-500">
                      Team Information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-500">
                      Use a permanent address where you can receive mail.
                    </p>
                  </div>

                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Username
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Role
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {users.map((person) => (
                        <tr key={person.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {person.username}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.isActive ? "Yes" : "No"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.role}
                          </td>
                          <td
                            className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                            hidden={person.id === currentUser?.id}
                          >
                            <a
                            href="#"
                              onClick={() => openRoleManagementModal(person)}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Edit Role
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-500">
                      Invite User
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-500">
                      Invite a team member.
                    </p>
                    {currentUserDetail?.role === "Owner" && (
                      <button
                        disabled={isInvitationBtnDisable()}
                        className={commonService.classNames(
                          "flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-2",
                          isSubmitBtnDisable() ? "disabled:opacity-75" : ""
                        )}
                        onClick={openInviteUserModal}
                      >
                        Invite
                      </button>
                    )}
                  </div>

                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Invitation Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {invitedUsers.map((person) => (
                        <tr key={person.email}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.role}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.isActive ? "Yes" : "No"} {/*to be updated to acceptence status*/}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {currentMenuSelection === 4 && (
              <div className="divide-y divide-white/5">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-500">
                      Invoice Status
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-500">
                      Pre-sale invoice evaluation process
                    </p>
                  </div>
                  {
                            loadingInvoiceStatus ? 
                            <Pulse show={loadingInvoiceStatus} /> :
                            <InvoiceStatusTable data={invoiceStatusResult || []} refreshDetails={refreshDetails} />
                        }
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  );
}