import { useEffect, useState } from 'react';
import OpportunityService from '../../app/service/opportunityService';
import { toast } from 'react-toastify';
import ProjectModal from './modal/projectModal';
import Spinner from '../../app/spinner/spinner';
import commonService from '../../app/service/commonService';
import { Tooltip } from 'react-tooltip';
import ProjectSelectModal from './modal/projectSelectModal';
import MaturitySelectModal from './modal/maturitySelectModal';
import PhaseSelectModal from './modal/phaseSelectModal';

interface Props {
    onProjectChange: (projectId: ProjectItem) => void,
    setLoadingProject: (loading: boolean) => void
}

export function FinopsPostureStats(props: Readonly<Props>) {

    const [projects, setProjects] = useState<ProjectItem[]>([]);

    const [showDetailModal, setShowDetailModal] = useState(false);
    
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [totalValue, setTotalValue] = useState<number | string>();
    const [totalHours, setTotalHours] = useState<number | string>();
    const [totalCompletedValue, setTotalCompletedValue] = useState<number | string>();
    const [totalCompletedHours, setTotalCompletedHours] = useState<number | string>();

    const [showProjectSelectModal, setShowProjectSelectModal] = useState(false);
    const [showMaturitySelectModal, setShowMaturitySelectModal] = useState(false);
    const [showPhaseSelectModal, setShowPhaseSelectModal] = useState(false);
    
    const [projectItem, setProjectItem] = useState<ProjectItem>(commonService?.initProjectItem);
  
    const openDetailModal = () => {
      setShowDetailModal(true);
    };
  
    const closeDetailModal = (refresh?: boolean) => {
      setShowDetailModal(false);
      if (refresh) {
        init();
      }
    };
  
    useEffect(() => {
      init();
    }, []);

    useEffect(() => {
        props.onProjectChange(projectItem);
    }, [projectItem]);
  
    const init = () => {
      if (commonService.getAccountId()) {
        getAllProject(commonService.getAccountId());
      }
    };
  
    const getAllProject = (accountId: string) => {
      setLoading(true);
      props.setLoadingProject(true);
      OpportunityService.getAllProject(accountId)
        .then((response: any) => {
          setLoading(false);
          props.setLoadingProject(false);
          const result: ProjectItem[] = response?.data?.result || [];
          sortProjects(result);
          setProjects(result);
          if (result?.length === 0) {
            openProjectModal();
          } else if (projectItem) {
            const targetProjectId = commonService.selectedProject ?? projectItem.id;
            let updatedItem = result.find(
              (p: ProjectItem) => p.id === targetProjectId
            );
            if (updatedItem) {
              setSelectedProject(updatedItem);
            } else if(commonService.selectedProject) {
              const currentProject = result.find(x => x.id === commonService.selectedProject);
              if(currentProject) {
                setSelectedProject(currentProject);
              } else {
                setSelectedProject(result[0]);
              }
            } else {
              setSelectedProject(result[0]);
            }
          } else {
            setSelectedProject(result[0]);
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
          props.setLoadingProject(false);
        });
    };

    const setSelectedProject = (project: ProjectItem) => {
        setProjectItem(project);
        calculateTotalHoursAndValue(project);
        commonService.selectedProject = project.id;
        props.onProjectChange(project);
    }

    const calculateTotalHoursAndValue = (project: ProjectItem) => {
        let totalHours = 0;
        let totalValue = 0;
        let totalCompletedHours = 0;
        let totalCompletedValue = 0;

        project?.opportunityPlan?.forEach((opportunityPlanItem) => {
            opportunityPlanItem.opportunityPlanStep.forEach((opportunityPlanStepItem) => {
                opportunityPlanStepItem.opportunityPlanResources.forEach((opportunityPlanResourceItem) => {
                    opportunityPlanResourceItem.opportunityPlanResourceTask.forEach((opportunityPlanResourceTaskItem) => {
                        totalHours += opportunityPlanResourceTaskItem.hours;
                        totalValue += opportunityPlanResourceTaskItem.value;
                        if(opportunityPlanResourceTaskItem.completedOn){
                            totalCompletedValue += opportunityPlanResourceTaskItem.value;
                            totalCompletedHours += opportunityPlanResourceTaskItem.hours;
                        }
                    });
                });
            });
        });

        setTotalHours(commonService.fixDecimal(totalHours));
        setTotalValue(commonService.fixDecimal(totalValue));
        setTotalCompletedHours(commonService.fixDecimal(totalCompletedHours));
        setTotalCompletedValue(commonService.fixDecimal(totalCompletedValue));
    }

    const sortProjects = (p: ProjectItem[]) => {
        p.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
        });
    }

    // const getCurrentMonthSpend
  
    const openProjectModal = () => {
      setIsEdit(false);
      openDetailModal();
    };
  
    const editProjectModal = () => {
      const selected = projects.find((p) => p.id === projectItem?.id);
      if (selected) {
        setProjectItem(selected);
        setIsEdit(true);
        openDetailModal();
      }
    };
  
    const onChangeProject = (project: ProjectItem) => {
        setSelectedProject(project);
    }

    const refreshDetails = (refresh?: boolean) => {
        if (refresh) {
            init();
        }
    };

    const reloadProject = (project: ProjectItem) => {
      if (project) {
        setSelectedProject(project);
      }
    };

    const openProjectSelectModal = () => {
        setShowProjectSelectModal(true);
      };
    
      const closeProjectSelectModal = (refresh?: boolean) => {
        setShowProjectSelectModal(false);
      };

      const openMaturitySelectModal = () => {
        setShowMaturitySelectModal(true);
      };
    
      const closeMaturitySelectModal = (refresh?: boolean) => {
        setShowMaturitySelectModal(false);
        if (refresh) {
          init();
        }
      };

      const openPhaseSelectModal = () => {
        setShowPhaseSelectModal(true);
      };
    
      const closePhaseSelectModal = (refresh?: boolean) => {
        setShowPhaseSelectModal(false);
        if (refresh) {
          init();
        }
      };

    return (
      <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
        {showDetailModal && (
          <ProjectModal
            show={showDetailModal}
            closeModal={closeDetailModal}
            projectItem={projectItem}
            isEdit={isEdit}
            refreshDetails={refreshDetails}
            reloadProject={reloadProject}
          ></ProjectModal>
        )}
        {showProjectSelectModal && (
          <ProjectSelectModal
            show={showProjectSelectModal}
            closeModal={closeProjectSelectModal}
            projects={projects}
            onProjectChange={onChangeProject}
            currentProject={projectItem}
          />
        )}
        {showMaturitySelectModal && (
          <MaturitySelectModal
            show={showMaturitySelectModal}
            closeModal={closeMaturitySelectModal}
            project={projectItem}
          />
        )}
        {showPhaseSelectModal && (
          <PhaseSelectModal
            show={showPhaseSelectModal}
            closeModal={closePhaseSelectModal}
            project={projectItem}
          />
        )}
        <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 lg:px-2 xl:px-0">
          <div className="px-2 py-5 sm:px-6 sm:py-8">
            <dt className="text-base font-normal text-gray-900">Project</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-black-600 truncate hover:text-blue-600">
                {loading ? (
                  <Spinner show={loading} />
                ) : (
                  <>
                    <a
                    href='#'
                      className="truncate cursor-pointer"
                      data-tooltip-id={"project-tooltip"}
                      onClick={openProjectSelectModal}
                    >
                      {projectItem?.name}
                    </a>
                    <Tooltip
                      id={"project-tooltip"}
                      arrowColor="transparent"
                      place="bottom"
                    >
                      <div style={{}}>
                        <span>{projectItem?.name}</span>
                      </div>
                    </Tooltip>
                  </>
                )}
              </div>
            </dd>
          </div>

          <div className="px-2 py-5 sm:px-6 sm:py-8">
            <dt className="text-base font-normal text-gray-900">Maturity</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-black-600 hover:text-blue-600">
                <a
                href='#'
                      className="cursor-pointer"
                      onClick={openMaturitySelectModal}
                    >
                      {projectItem?.maturity}
                    </a>
              </div>
            </dd>
          </div>

          <div className="px-2 py-5 sm:px-6 sm:py-8">
            <dt className="text-base font-normal text-gray-900">Phase</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-black-600 hover:text-blue-600">
                <a
                href='#'
                      className="cursor-pointer"
                      onClick={openPhaseSelectModal}
                    >
                      {projectItem?.phase}
                    </a>
              </div>
            </dd>
          </div>

          <div className="col-span-2 lg:col-span-2 px-2 py-5 sm:px-6 sm:py-8">
            <dt className="text-base font-normal text-gray-900">
              Saving Target
            </dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-black-600">
                ${totalCompletedValue}
                <span className="ml-2 text-sm font-medium text-gray-500">
                  from ${totalValue},{" "}
                </span>
                <span className="ml-2 text-sm font-medium text-gray-500">
                  Work Hrs {totalCompletedHours} out of {totalHours}
                </span>
              </div>
            </dd>
          </div>

          <div className="px-2 py-5 sm:px-6 sm:py-8">
            <dt className="text-base font-normal text-gray-900">Action</dt>
            <dd className="mt-1 flex items-baseline justify-start md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-black-600">
                {projectItem && (
                  <div className="mt-1 sm:ml-0 sm:mt-0 sm:flex-none">
                    <button
                      type="button"
                      className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => editProjectModal()}
                    >
                      Edit
                    </button>
                  </div>
                )}
                <div className="mt-1 sm:ml-4 sm:mt-0 sm:flex-none">
                  <button
                    type="button"
                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => openProjectModal()}
                  >
                    Create
                  </button>
                </div>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    );
}
