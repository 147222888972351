
import { InformationCircleIcon, ArrowTopRightOnSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { EmptyView, ResourceLink, SortTableHeader, TabsView } from "../../app/common/commonControls";
import { ErrorsTooltip, RecommendationsTooltip } from "../../app/common/tooltipControl";
import Pulse from "../../app/pulse/pulse";
import CloudResourceService from "../../app/service/cloudResourceService";
import commonService from "../../app/service/commonService";
import OpportunityService from "../../app/service/opportunityService";
import PolicyService from "../../app/service/policyService";
import Spinner from "../../app/spinner/spinner";
import { TableHead, TableSort } from "../common/sortingControl";
import CloudResourcesModal from "../initiatives/modal/cloudResourceModal";
import CloudResourceSnapshotModal from "../initiatives/modal/cloudResourceSnapshotModal";
import ComplianceModal from "../initiatives/modal/complianceModal";
import FilterModal from "../initiatives/modal/filterModal";
import JSONViewModal from "../initiatives/modal/jsonViewModal";
import ProtectedVmModal from "../initiatives/modal/protectedVmModal";
import StorageAccountModal from "../initiatives/modal/storageAccountModal";
import StorageCostBreakDownModal from "../initiatives/modal/storageCostBreakdownModal";
import UnattachedStorageInvoiceModal from "../initiatives/modal/unattachedStorageInvoiceModal";
import VMDetailModal from "../initiatives/modal/vmModal";
import VMRightSizeModal from "../initiatives/modal/vmRightSize";

export default function CloudResources() {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUnattachedStorage, setLoadingUnattachedStorage] = useState<boolean>(false);
  const [loadingStorageAccount, setLoadingStorageAccount] = useState<boolean>(false);
  const [loadingGraphData, setLoadingGraphData] = useState<boolean>(false);
  const [vmData, setVmData] = useState<any[]>([]);
  const [vmFilteredData, setVmFilteredData] = useState<any[]>([]);
  const lookBackList: number[] = [1, 7, 15, 30, 60, 90];
  const timeFrameList: Array<{timeFrame: string, value: number}> = [
    {timeFrame : '1 Hour', value: 1},
    {timeFrame : '1 Day', value: 24},
    {timeFrame : '1 Week', value: 168},
    {timeFrame : '1 Month', value: 720},
    {timeFrame : '1 Year', value: 8640}
  ];
  const commitmentPeriodsList: Array<{name: string, value: string}> = [
    {name : '1 YRS', value: 'license1Year'},
    {name : '3 YRS', value: 'license3Year'}
  ];
  const [timeFrame, setTimeFrame] = useState<number>(timeFrameList[0].value);
  const [commitmentPeriod, setCommitmentPeriod] = useState<string>(commitmentPeriodsList[1].value);
  const [lookBackPeriod, setLookBackPeriod] = useState<number>(30);

  const all = "All";

  const [hasFilter, setHasFilter] = useState<boolean>(false);

  const tabs = [
    { name: 'Virtual Machine', href: '#', current: true },
    { name: 'Waste', href: '#', current: false },
    { name: 'Storage', href: '#', current: false },
    { name: 'Graph Monitor', href: '#', current: false }
  ];

  const [currentTabSelection, setCurrentTabSelection] = useState<string>(
    tabs[0]?.name
  );

  const [unattachedStorage, setUnattachedStorage] = useState<AzureGenericResourceItem[]>([]);
  const [unattachedStorageDataFiltered, setUnattachedStorageDataFiltered] = useState<AzureGenericResourceItem[]>([]);
  const [storageAccounts, setStorageAccounts] = useState<any[]>([]);
  const [storageAccountsFilteredData, setStorageAccountsFilteredData] = useState<any[]>([]);

  const [complianceData, setComplianceData] = useState<any[]>([]);
  const [loadingCompliance, setLoadingCompliance] = useState<boolean>(false);

  const [loadingProtectedVM, setLoadingProtectedVM] = useState<boolean>(false);

  useEffect(() => {
    setCurrentTabSelection(tabs[0].name);
    getFinOpsSummaryVMs(lookBackPeriod);
    getListUnattachedDisk();
    getListStorageAccounts(lookBackPeriodForStorage);
    getPlanResourceTaskTemplate();
    getListMonitorData();
  }, []);

  const getFinOpsSummaryVMs = (lookBackPeriodData: number) => {
    setLoading(true);
    const customerId = commonService.getAccountId();
    CloudResourceService.getFinOpsSummaryVMs(
      customerId,
      lookBackPeriodData
    )
      .then((response: any) => {
        setLoading(false);
        const result = response?.data?.result || [];
        result.forEach((x: any) => {
          const invoiceInfo = x.invoiceInfo || [];
          x.diskCost = 0;
          if(invoiceInfo?.length > 0) {
            let cost = 0;
            invoiceInfo.forEach((i: any) => {
              cost += (i.cost || 0);
            });
            x.currencyCode = invoiceInfo[0].billingCurrency;
            x.diskCost = cost;
          }
        });
        setVmData((vms) => [...vms, ...result]);
        setVmFilteredData(result);
        getResourcePolicyComplianceStatus(result);
        getDetectProtectedVm(result);
        setFilters(result, filterData);
        setTopFilters(result, timeFrame, commitmentPeriod);
    })
    .catch((_: any) => {
          setLoading(false);
        });
  };

  const parseComplicanceData = (vm: any, responseList: any[]) => {
    return vm.map((x: any) => {
      let complianceCount = 0;
      let tooltipText = "";
      const complianceList =
        (responseList || []).find(
          (r) =>
            r?.resourceId?.toLowerCase() === x?.resourceId?.toLowerCase()
        )?.complianceList || [];
      complianceList.forEach((c: any) => {
        if (c.complianceState === "Compliant") {
          complianceCount++;
        }
        tooltipText = `${tooltipText} ${c.definitionProperties?.displayName} : ${c.complianceState} \n`;
      });
      return {
        ...x,
        complianceList: complianceList,
        complianceCount: complianceCount,
        nonComplianceCount: complianceList.length - complianceCount,
        complianceTooltipText: tooltipText,
      };
    })
  }

  const getChunkArrayRequest = (resourceIds: any[]) => {
    return (resourceIds || []).reduce((resultArray, item, index) => {
      const arrayIndex = Math.floor(index / 10)
      if (!resultArray[arrayIndex]) {
        resultArray[arrayIndex] = [] // start a new array
      }
      resultArray[arrayIndex].push(item)
      return resultArray
    }, []);
  }

  const getResourcePolicyComplianceStatus = (data: any[]) => {
    const resourceIds = (data || []).map(x => x.resourceId?.toLowerCase());
    const requestList: any[] = [];

    const resultList = getChunkArrayRequest(resourceIds) || [];

    resultList.forEach((x: any) => {
      const request: ResourcePolicyComplianceStatusRequest = {
        resourceIds: x
      }
      requestList.push(PolicyService.getResourcePolicyComplianceStatus(request));
    });

    setLoadingCompliance(true);
    Promise.all(requestList)
    .then((responses) => {
      const responseList: any[] = [];
      responses.forEach((response) => {
        const result = response?.data?.result;
        if(result) {
          responseList.push(...result);
        }
      });

      setVmData((vm) => parseComplicanceData(vm, responseList));

      setVmFilteredData((vm) => parseComplicanceData(vm, responseList));

      setLoadingCompliance(false);
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      setLoadingCompliance(false);
    }); 
  };

  const getDetectProtectedVm = (data: any[]) => {
    const resourceIds = (data || []).map(x => x.resourceId?.toLowerCase());
    const requestList: any[] = [];

    const resultList = getChunkArrayRequest(resourceIds) || [];

    resultList.forEach((x: any) => {
      const request: AzureResourceDetectProtectedVmRequest = {
        resourceIds: x
      }
      requestList.push(CloudResourceService.getDetectProtectedVm(request));
    });

    setLoadingProtectedVM(true);
    Promise.all(requestList)
    .then((responses) => {
      const responseList: AzureResourceDetectProtectedVmItem[] = [];
      responses.forEach((response) => {
        const result = response?.data?.result;
        if(result) {
          responseList.push(...result);
        }
      });

      setVmData((vm) => parseProtectedVm(vm, responseList));

      setVmFilteredData((vm) => parseProtectedVm(vm, responseList));

      setLoadingProtectedVM(false);
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      setLoadingProtectedVM(false);
    }); 
  };

  const parseProtectedVm = (vm: any, responseList: AzureResourceDetectProtectedVmItem[]) => {
    const mapData = vm.map((x: any) => {
      let tooltipText = "";
      const protectionDetail =
        (responseList || []).find(
          (r) =>
            r?.resourceId?.toLowerCase() === x?.resourceId?.toLowerCase()
        )?.protectionDetail || [];

        if(protectionDetail?.length > 0) {
          protectionDetail.forEach(p => tooltipText = `${tooltipText}${p.vaultId}\n`)
        }
        if(tooltipText?.trim()?.length === 0) {
          tooltipText = 'No Vault Found';
        }

      return {
        ...x,
        protectionDetail: protectionDetail,
        protectedVaultTooltipText: tooltipText
      };
    });
    return mapData;
  }

  const getListUnattachedDisk = () => {
    setLoadingUnattachedStorage(true);
    if(startDate && endDate) {
    const customerId = commonService.getAccountId();
    const request: AzureUnattachedDiskRequest = {
      customerId: customerId,
      startDate: startDate,
      endDate: endDate
    }
    CloudResourceService.getListUnattachedDisk(
      request
    )
      .then((response: any) => {
        setLoadingUnattachedStorage(false);
        const result = response?.data?.result || [];
        result.forEach((x: AzureGenericResourceItem) => {
          const invoiceInfo = x.invoiceInfo || [];
          x.totalCost = 0;
          if(invoiceInfo?.length > 0) {
            let cost = 0;
            invoiceInfo.forEach(i => {
              cost += (i.cost || 0);
            });
            x.currencyCode = invoiceInfo[0].billingCurrency;
            x.totalCost = cost;
          }
        });
        setUnattachedStorage(result);
        setUnattachedStorageDataFiltered(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoadingUnattachedStorage(false);
      });
    }
  };

  const parseStorageAccounts = (data: any) => {
    data.forEach((x: any) => {
      const invoiceData = x.invoiceInfo || [];
      let cost = 0;
      let tooltipVal: any = {};
      invoiceData.forEach((i: any) => {
        cost += i.cost;
        const meterName = i?.meterName || '';
        if(tooltipVal?.hasOwnProperty(meterName)) {
          tooltipVal[meterName] += i.cost;
        } else {
          tooltipVal[meterName] = i.cost;
        }
      });
      x.costVal = commonService.fixDecimal(cost);
      x.tooltipVal = tooltipVal;
    });
    return data;
  }

  const getListStorageAccounts = (lookBackPeriodVal: number) => {
    setLoadingStorageAccount(true);
    const customerId = commonService.getAccountId();
    const request: AzureStorageAccountsRequest = {
      customerId: customerId,
      lookBackPeriodDays: lookBackPeriodVal,
      resetCache: false
    }
    CloudResourceService.getListStorageAccounts(
      request
    )
      .then((response: any) => {
        setLoadingStorageAccount(false);
        const result = parseStorageAccounts(response?.data?.result || []);
        setStorageAccounts(result);
          setStorageAccountsFilteredData(result);
          setFiltersForStorage(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoadingStorageAccount(false);
      });
  };

  const onChangeLookback = (e: any) => {
    setLookBackPeriod(Number(e?.target?.value));
    getFinOpsSummaryVMs(Number(e?.target?.value));
  };

  const onChangeLookbackForStorage = (e: any) => {
    setLookBackPeriodForStorage(Number(e?.target?.value));
    getListStorageAccounts(Number(e?.target?.value));
  };

  const [showVMDetailModal, setShowVMDetailModal] = useState<boolean>(false);
  const [selectedResourceId, setSelectedResourceId] = useState<string>('');
  const [subscriptionList, setSubscriptionList] = useState<Array<{subscriptionName: string, subscriptionId: string, rgList: string[]}>>([]);
  const [subscription, setSubscription] = useState<string>(all);
  const [resourceGroupList, setResourceGroupList] = useState<string[]>([]);
  const [resourceGroup, setResourceGroup] = useState<string>(all);


  const [timeFrameForStorage, setTimeFrameForStorage] = useState<number>(timeFrameList[0].value);
  const [commitmentPeriodForStorage, setCommitmentPeriodForStorage] = useState<string>(commitmentPeriodsList[1].value);
  const [lookBackPeriodForStorage, setLookBackPeriodForStorage] = useState<number>(30);
  const [subscriptionListForStorage, setSubscriptionListForStorage] = useState<Array<{subscriptionName: string, subscriptionId: string, rgList: string[]}>>([]);
  const [subscriptionForStorage, setSubscriptionForStorage] = useState<string>(all);
  const [resourceGroupListForStorage, setResourceGroupListForStorage] = useState<string[]>([]);
  const [resourceGroupForStorage, setResourceGroupForStorage] = useState<string>(all);

  const openVMDetailModal = (resourceId: string) => {
    const type = resourceId?.split('/')[6];
    if(type === 'Microsoft.Compute') {
      setSelectedResourceId(resourceId);
      setShowVMDetailModal(true);
    }
  }

  const closeVMDetailModal = () => {
    setShowVMDetailModal(false);
  };

  const onChangeTimeFrame = (e: any) => {
    setTimeFrame(e?.target?.value);
    setTopFilters(vmFilteredData, Number(e?.target?.value), commitmentPeriod);
  }

  const onChangeTimeFrameForStorage = (e: any) => {
    setTimeFrameForStorage(e?.target?.value);
  }

  const onChangeCommitmentPeriod = (e: any) => {
    setCommitmentPeriod(e?.target?.value);
    setTopFilters(vmFilteredData, timeFrame, e?.target?.value);
  }

  const onChangeCommitmentPeriodForStorage = (e: any) => {
    setCommitmentPeriodForStorage(e?.target?.value);
  }

  const setFiltersForStorage = (data: any[]) => {
    const subscriptions: Array<{subscriptionName: string, subscriptionId: string, rgList: string[]}> = [{ subscriptionName: all, subscriptionId: all, rgList: [all] }];
    (data || []).forEach(x => {
      const resourceGroupName = x.resourceId?.split('/')[4] || '';
      if(subscriptions[0].rgList.indexOf(resourceGroupName) === -1) {
        subscriptions[0].rgList.push(resourceGroupName);
      }
      const i = subscriptions.findIndex(s => s.subscriptionId === x.subscriptionId);
      if(i === -1) {
        subscriptions.push({ subscriptionName: x.subscription?.name, subscriptionId: x.subscriptionId, rgList: [all, resourceGroupName] });
      } else if((subscriptions[i].rgList || []).indexOf(resourceGroupName) === -1) {
          subscriptions[i].rgList.push(resourceGroupName);
      }
    });
    setSubscriptionListForStorage(subscriptions);
    setResourceGroupListForStorage(subscriptions[0].rgList);
    setSubscriptionForStorage(all);
    setResourceGroupForStorage(all);
  }

  const getRunTimeHoursPercentage = (vm: any) => {
    return parseFloat(commonService.fixDecimal(((vm.runTimeHours || 0) / (vm.lookBackPeriodHours || 0)) * 100 || 0));
  }

  const setFilters = (data: any[], filterRawData: CloudResourceFilter) => {
    initFiltersMax();
    const subscriptions: Array<{subscriptionName: string, subscriptionId: string, rgList: string[]}> = [{ subscriptionName: all, subscriptionId: all, rgList: [all] }];
    (data || []).forEach(x => {
      if(subscriptions[0].rgList.indexOf(x.resourceGroup) === -1) {
        subscriptions[0].rgList.push(x.resourceGroup);
      }
      const i = subscriptions.findIndex(s => s.subscriptionId === x.subscription?.id);
      if(i === -1) {
        subscriptions.push({ subscriptionName: x.subscription?.name, subscriptionId: x.subscription?.id, rgList: [all, x.resourceGroup] });
      } else if((subscriptions[i].rgList || []).indexOf(x.resourceGroup) === -1) {
          subscriptions[i].rgList.push(x.resourceGroup);
      }
      filterRawData.percentage.max = 100;
      const recommendationsLength = (x?.recommendations || []).length;
      if(recommendationsLength > filterRawData.recommendationsCount?.max) {
        filterRawData.recommendationsCount.max = recommendationsLength;
      }
      const errorsLength = (x?.errors || []).length;
      if(errorsLength > filterRawData?.errosCount?.max) {
        filterRawData.errosCount.max = errorsLength;
      }
      const maxTimeFrame = 8640 * 3;
      const hardwareCurrent = parseFloat(commonService.fixDecimal(((x?.hardwareCommitent?.hardwarePaygCost?.retailPrice || 0) * maxTimeFrame), 0));
      if(hardwareCurrent > filterRawData.hardwareCurrent?.max) {
        filterRawData.hardwareCurrent.max = hardwareCurrent;
      }

      const reservationSavings = parseFloat(commonService.fixDecimal(((x?.hardwareCommitent?.savingsPerHour || 0) * maxTimeFrame), 0));
      if(reservationSavings > filterRawData.reservationSavings?.max) {
        filterRawData.reservationSavings.max = reservationSavings;
      }

      filterRawData.reservationBreakEven.max = 100;

      const hybridBenefisSavingsPerHour = x?.hybridBenefis?.license3Year?.savingsPerHour; 
      const licencingCurrent = parseFloat(commonService.fixDecimal(((x?.hybridBenefis?.osPaygCost || 0) * maxTimeFrame), 0));
      if(licencingCurrent > filterRawData.licensingCurrent?.max) {
        filterRawData.licensingCurrent.max = licencingCurrent;
      }

      const purchaseSavings = parseFloat(commonService.fixDecimal(((hybridBenefisSavingsPerHour || 0) * maxTimeFrame), 0));
      if(purchaseSavings > filterRawData.licensingSavings?.max) {
        filterRawData.licensingSavings.max = purchaseSavings;
      }
      filterRawData.licensingBreakEven.max = 100;
      let diskSize = x?.disk?.osDisk?.size || 0;
      (x.disk?.dataDisks || []).forEach((d: any) => {
        if((d.size || 0) > diskSize) {
          diskSize = d.size || 0;
        }
      });
      if(diskSize > filterRawData.diskSize?.max) {
        filterRawData.diskSize.max = diskSize;
      }
    });
    initDefaultFilters(filterRawData);
    setSubscriptionList(subscriptions);
    setResourceGroupList(subscriptions[0].rgList);
    setSubscription(all);
    setResourceGroup(all);
  }

  const initDefaultFilters = (filterRawData: CloudResourceFilter) => {


    filterRawData.percentage.to = filterRawData.percentage?.max || 0;
    filterRawData.recommendationsCount.to = filterRawData.recommendationsCount?.max || 0;
    filterRawData.errosCount.to = filterRawData.errosCount?.max || 0;
    filterRawData.hardwareCurrent.to = filterRawData.hardwareCurrent?.max || 0;
    filterRawData.reservationSavings.to = filterRawData.reservationSavings?.max || 0;
    filterRawData.reservationBreakEven.to = filterRawData.reservationBreakEven?.max || 0;
    filterRawData.licensingCurrent.to = filterRawData.licensingCurrent?.max || 0;
    filterRawData.licensingSavings.to = filterRawData.licensingSavings?.max || 0;
    filterRawData.licensingBreakEven.to = filterRawData.licensingBreakEven?.max || 0;
    filterRawData.diskSize.to = filterRawData.diskSize?.max || 0;
    setFilterData(filterRawData);
  }

  const onChangeSubscription = (e: any) => {
    setSubscription(e?.target?.value);
    filterResourceGroup(e?.target?.value);
    setResourceGroup(all);
    filterVM(filterData, e?.target?.value, all, headFilters, true);
  }

  const onChangeSubscriptionForStorage = (e: any) => {
    setSubscriptionForStorage(e?.target?.value);
    filterResourceGroupForStorage(e?.target?.value);
    setResourceGroupForStorage(all);
    filterStorageAccount(e?.target?.value, all);
  }

  const filterResourceGroup = (sub: string) => {
    const selectedResourceGroup = subscriptionList.find(x => x.subscriptionId === sub)?.rgList || [];
    setResourceGroupList(selectedResourceGroup);
  }

  const filterResourceGroupForStorage = (sub: string) => {
    const selectedResourceGroup = subscriptionList.find(x => x.subscriptionId === sub)?.rgList || [];
    setResourceGroupListForStorage(selectedResourceGroup);
  }

  const onChangeResourceGroup = (e: any) => {
    setResourceGroup(e?.target?.value);
    filterVM(filterData, subscription, e?.target?.value, headFilters, true);
  }

  const onChangeResourceGroupForStorage = (e: any) => {
    setResourceGroupForStorage(e?.target?.value);
    filterStorageAccount(subscriptionForStorage, e?.target?.value);
  }

  const nameSortList: NameValuePair[] = [
    {name: 'Name', value: 'name'},
    {name: 'Location', value: 'location'},
    {name: 'Size', value: 'size'},
    {name: 'Operating System', value: 'operativeSystem'}
  ];
  const runTimeList: NameValuePair[] = [
    {name: 'Percentage', value: 'percentage'},
    {name: 'Recommendations', value: 'recommendations'},
    {name: 'Errors', value: 'errors'}
  ];
  const hardwareList: NameValuePair[] = [
    {name: 'Current', value: 'hardwareCurrent'},
    {name: 'Res Savings', value: 'hardwareResSavings'}
  ];
  const licenseList: NameValuePair[] = [
    {name: 'Current', value: 'linenseCurrent'},
    {name: 'Pur Savings', value: 'lincensePurSavings'}
  ];
  const diskList: NameValuePair[] = [
    {name: 'Type', value: 'diskType'},
    {name: 'Size', value: 'diskSize'}
  ];

  const [sortBy, setSortBy] = useState<string>('');
  const [asc, setAsc] = useState<boolean>(false);

  const isSorted = (name: string) => {
    return name === sortBy;
  }

  const sortClick = (name: string) => {
    let newAsc: boolean;
    if(name !== sortBy) {
      setSortBy(name);
      setAsc(true);
      newAsc = true;
    } else {
      setAsc(!asc);
      newAsc = !asc;
    }
    sortData(newAsc);
  }

  const sortData = (newAsc: boolean) => {
    setLoading(true);
    const sortedData = (vmFilteredData || []).sort((a: any, b: any) => {
      let nameA = null;
      let nameB = null;
      if(sortBy === 'percentage') {
        nameA = getRunTimeHoursPercentage(a);
        nameB = getRunTimeHoursPercentage(b);
      } else if(sortBy === 'recommendations') {
        nameA = (a.recommendations || []).length || 0;
        nameB = (b.recommendations || []).length || 0;
      } else if(sortBy === 'errors') {
        nameA = (a.errors || []).length || 0;
        nameB = (b.errors || []).length || 0;
      } else if(sortBy === 'hardwareCurrent') {
        nameA = parseFloat(hardwareCurrentPrice(a, timeFrame)) || 0;
        nameB = parseFloat(hardwareCurrentPrice(b, timeFrame)) || 0
      } else if(sortBy === 'hardwareResSavings') {
        nameA = hardwareSavings(a, commitmentPeriod, timeFrame) || 0;
        nameB = hardwareSavings(b, commitmentPeriod, timeFrame) || 0;
      } else if(sortBy === 'linenseCurrent') {
        nameA = a?.hybridBenefis?.osPaygCost || 0;
        nameB = b?.hybridBenefis?.osPaygCost || 0;
      } else if(sortBy === 'lincensePurSavings') {
        if(is1Year()) {
          nameA = a?.hybridBenefis?.license1Year?.savingsPerHour || 0;
          nameB = b?.hybridBenefis?.license1Year?.savingsPerHour || 0;
        } else {
          nameA = a?.hybridBenefis?.license3Year?.savingsPerHour || 0;
          nameB = b?.hybridBenefis?.license3Year?.savingsPerHour || 0;
        }
        
      } else if(sortBy === 'diskType') {
        nameA = (a?.disk?.dataDisks || []).length || 0;
        nameB = (b?.disk?.dataDisks || []).length || 0;
      } else if(sortBy === 'diskSize') {
        nameA = a?.disk?.osDisk?.size || 0;
        nameB = b?.disk?.osDisk?.size || 0;
      } else if(sortBy === 'diskCost') {
        nameA = a?.diskCost || 0;
        nameB = b?.diskCost || 0;
      } else {
        nameA = a[sortBy]?.toString()?.toLowerCase();
        nameB = b[sortBy]?.toString()?.toLowerCase();
      }
      
      if(!newAsc) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      }
      
  });
  setVmFilteredData(sortedData);
  setLoading(false);
  }

  const initFilters = (): CloudResourceFilter => {
    return {
      name: {
        value: '',
        filter: false,
        displayName: 'Name',
        type: 'value'
      },
      location: {
        value: '',
        filter: false,
        displayName: 'Location',
        type: 'value'
      },
      size: {
        value: '',
        filter: false,
        displayName: 'Size',
        type: 'value'
      },
      os: {
        value: '',
        filter: false,
        displayName: 'OS',
        type: 'value'
      },
      percentage: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'Percentage',
        type: 'range'
      },
      recommendationsCount: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'Recommendations',
        type: 'range'
      },
      errosCount: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'Errors',
        type: 'range'
      },
      hardwareCurrent: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'Hardware Current',
        type: 'range'
      },
      reservationSavings: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'Reservation Savings',
        type: 'range'
      },
      reservationBreakEven: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'Reservation Break Even',
        type: 'range'
      },
      licensingCurrent: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'License Current',
        type: 'range'
      },
      licensingSavings: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'Purchase Savings',
        type: 'range'
      },
      licensingBreakEven: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'Purchase Break Even',
        type: 'range'
      },
      diskType: {
        value: '',
        filter: false,
        displayName: 'Disk Type',
        type: 'value'
      },
      diskSize: {
        from: 0,
        to: 0,
        filter: false,
        max: 0,
        displayName: 'Disk Size',
        type: 'range'
      },
      ahbuStatus: {
        value: '',
        filter: false,
        displayName: 'AHBU Status',
        type: 'value'
      }
    }
  }

  const initFiltersMax = () => {
    filterData.percentage.max = 0;
    filterData.recommendationsCount.max = 0;
    filterData.errosCount.max = 0;
    filterData.hardwareCurrent.max = 0;
    filterData.reservationSavings.max = 0;
    filterData.reservationBreakEven.max = 0;
    filterData.licensingCurrent.max = 0;
    filterData.licensingSavings.max = 0;
    filterData.licensingBreakEven.max = 0;
    filterData.diskSize.max = 0;
  }

  const [filterData, setFilterData] = useState<CloudResourceFilter>(initFilters());

  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  const openFilterModal = () => {
    setShowFilterModal(true);
  }

  const closeFilterModal = () => {
    setShowFilterModal(false);
  };

  const refreshDetails = (data: CloudResourceFilter) => {
    setFilterData(data);
    checkFilters(data);
    filterVM(data, subscription, resourceGroup, headFilters, true);
  }

  const isValidNumber = (val: any) => {
    return val?.toString()?.trim()?.length > 0 && val != 0;
  }

  const [loadingTable, setLoadingTable] = useState<boolean>(false);

  const filterVM = (data: CloudResourceFilter, currentSub: string, currentRG: string, headFiltersData: any, fromFilterChange?: boolean) => {
    setLoadingTable(true);
    setTimeout(() => {
      const filterVMData: any[] = [];
    const currentVMData = JSON.parse(JSON.stringify(vmData || []));
    currentVMData.forEach((x: any) => {
      const percentage = getRunTimeHoursPercentage(x);
      const recommendationsLength = (x?.recommendations || []).length;
      const errorsLength = (x?.errors || []).length;
      const hardwareCurrent = parseFloat(hardwareCurrentPrice(x, timeFrame));
      const reservationSavings = hardwareSavings(x, commitmentPeriod, timeFrame);
      const reservationBreakEven = parseFloat(breakEvenRunTimePercentage(x, commitmentPeriod));
      const hybridBenefisSavingsPerHour = is1Year() ? x?.hybridBenefis?.license1Year?.savingsPerHour: x?.hybridBenefis?.license3Year?.savingsPerHour; 
      const hybridBenefisBreakEvenRunTimePercentage = is1Year() ? x?.hybridBenefis?.license1Year?.breakEvenRunTimePercentage: x?.hybridBenefis?.license3Year?.breakEvenRunTimePercentage;
      const licencingCurrent = parseFloat(commonService.fixDecimal((x?.hybridBenefis?.osPaygCost || 0) * timeFrame));
      const purchaseSavings = parseFloat(commonService.fixDecimal(hybridBenefisSavingsPerHour || 0));
      const purchaseBreakEven = parseFloat(commonService.fixDecimal((hybridBenefisBreakEvenRunTimePercentage || 0) * 100));
      
      if((!headFiltersData[0].selected || reservationBreakEven < percentage) &&
      (!headFiltersData[1].selected || (purchaseBreakEven < percentage && !x?.hybridBenefis?.currentSetting && 
        !(x?.hybridBenefis?.isHbuWindowsCandidate === "No" || x?.hybridBenefis?.isHbuWindowsCandidate === "Undefined" || !x?.hybridBenefis?.isHbuWindowsCandidate))) 
        &&
      (!data?.name?.value || x.name?.toString()?.toLowerCase().includes(data?.name?.value?.toString()?.toLowerCase())) &&
      (!data?.location?.value || data?.location?.value === x.location) &&
      (!data?.size?.value || data?.size?.value === commonService.sizePipe(x.size)) &&
      (!data?.os?.value || data?.os?.value === x.operativeSystem) 
      &&
      (!isValidNumber(data?.percentage?.from) || parseFloat(data?.percentage?.from) <= percentage) 
      &&
      (!isValidNumber(data?.percentage?.to) || parseFloat(data?.percentage?.to) >= percentage) &&
      (!isValidNumber(data?.recommendationsCount?.from) || parseFloat(data?.recommendationsCount?.from) <= recommendationsLength) &&
      (!isValidNumber(data?.recommendationsCount?.to) || parseFloat(data?.recommendationsCount?.to) >= recommendationsLength) &&
      (!isValidNumber(data?.errosCount?.from) || parseFloat(data?.errosCount?.from) <= errorsLength) &&
      (!isValidNumber(data?.errosCount?.to) || parseFloat(data?.errosCount?.to) >= errorsLength) 
      &&
      (!isValidNumber(data?.hardwareCurrent?.from) || parseFloat(data?.hardwareCurrent?.from) <= hardwareCurrent) &&
      (!isValidNumber(data?.hardwareCurrent?.to) || parseFloat(data?.hardwareCurrent?.to) >= hardwareCurrent) 
      &&
      (!isValidNumber(data?.reservationSavings?.from) || parseFloat(data?.reservationSavings?.from) <= reservationSavings) &&
      (!isValidNumber(data?.reservationSavings?.to) || parseFloat(data?.reservationSavings?.to) >= reservationSavings) &&
      (!isValidNumber(data?.reservationBreakEven?.from) || parseFloat(data?.reservationBreakEven?.from) <= reservationBreakEven) &&
      (!isValidNumber(data?.reservationBreakEven?.to) || parseFloat(data?.reservationBreakEven?.to) >= reservationBreakEven) 
      &&
      (!isValidNumber(data?.licensingCurrent?.from) || parseFloat(data?.licensingCurrent?.from) <= licencingCurrent) &&
      (!isValidNumber(data?.licensingCurrent?.to) || parseFloat(data?.licensingCurrent?.to) >= licencingCurrent) &&
      (!isValidNumber(data?.licensingSavings?.from) || parseFloat(data?.licensingSavings?.from) <= purchaseSavings) &&
      (!isValidNumber(data?.licensingSavings?.to) || parseFloat(data?.licensingSavings?.to) >= purchaseSavings) &&
      (!isValidNumber(data?.licensingBreakEven?.from) || parseFloat(data?.licensingBreakEven?.from) <= purchaseBreakEven) 
      &&
      (!isValidNumber(data?.licensingBreakEven?.to) || parseFloat(data?.licensingBreakEven?.to) >= purchaseBreakEven) 
      &&
      (!data?.diskType?.value || (data?.diskType?.value === 'os' && x?.disk?.osDisk?.sku) || (data?.diskType?.value === 'data' && (x?.disk?.dataDisks || [])?.length >0)) 
      &&
      (!isValidNumber(data?.diskSize?.from) || parseFloat(data?.diskSize?.from) <= x?.disk?.osDisk?.size) &&
      (!isValidNumber(data?.diskSize?.to) || parseFloat(data?.diskSize?.to) >= x?.disk?.osDisk?.size) &&
      ((currentSub === all && currentRG === all) || (currentSub === all && currentRG === x.resourceGroup) || 
      (currentRG === all && currentSub === x.subscription?.id) || (currentSub === x.subscription?.id && currentRG === x.resourceGroup))
      && (!data?.ahbuStatus?.value || (data?.ahbuStatus?.value === 'enabled' && !(x?.hybridBenefis?.isHbuWindowsCandidate === "No" || x?.hybridBenefis?.isHbuWindowsCandidate === "Undefined"  || !x?.hybridBenefis?.isHbuWindowsCandidate)) || (data?.ahbuStatus?.value === 'disabled' && ((x?.hybridBenefis?.isHbuWindowsCandidate === "No" || x?.hybridBenefis?.isHbuWindowsCandidate === "Undefined" || !x?.hybridBenefis?.isHbuWindowsCandidate)))) 
      ) {
        filterVMData.push(x);
      }
    });
    setVmFilteredData(filterVMData);
    if(fromFilterChange) {
      setTopFilters(filterVMData, timeFrame, commitmentPeriod);
    }
    setLoadingTable(false);
    }, 100);
  }

  const filterStorageAccount = (currentSub: string, currentRG: string) => {
    const filterStorageAccountData: any[] = [];
    const currentStorageAccountMData = JSON.parse(JSON.stringify(storageAccounts || []));
    currentStorageAccountMData.forEach((x: any) => {
      const resourceGroupName = x.resourceId?.split('/')[4] || '';
      if((currentSub === all && currentRG === all) || (currentSub === all && currentRG === resourceGroupName) || 
      (currentRG === all && currentSub === x.subscriptionId) || (currentSub === x.subscriptionId && currentRG === resourceGroupName)) {
        filterStorageAccountData.push(x);
      }
    });
    setStorageAccountsFilteredData(filterStorageAccountData);
  }

  const clearFilters = () => {
    setHasFilter(false);
    const data = initFilters();
    setFilters(vmData, data);
    setVmFilteredData(JSON.parse(JSON.stringify(vmData)));
    setSubscription(all);
    setResourceGroup(all);
    setHasFilter(false);
  }

  const is1Year = () => {
    return commitmentPeriod === 'license1Year';
  }

  const [showVMRightSizeModal, setShowVMRightSizeModal] = useState<boolean>(false);
  const [size, setSize] = useState<string>('');
  const [location, setLocation] = useState<string>('');

  const openVMRightSizeModal = (vm: any) => {
    const type = vm?.resourceId?.split('/')[6];
    if(type === 'Microsoft.Compute') {
      setSelectedResourceId(vm?.resourceId);
      setSize(vm?.size);
      setLocation(vm?.armLocation);
      setShowVMRightSizeModal(true);
    }
  }

  const closeVMRightSizeModal = () => {
    setShowVMRightSizeModal(false);
  };

  const [showInvoiceInfoModal, setShowInvoiceInfoModal] = useState<boolean>(false);
  const [invoiceInfo, setInvoiceInfo] = useState<any>([]);

  const openInvoiceInfoModal = (storageAccount: any) => {
    setInvoiceInfo(storageAccount?.invoiceInfo || []);
    setShowInvoiceInfoModal(true);
  }

  const closeInvoiceInfoModal = () => {
    setShowInvoiceInfoModal(false);
  }; 

  const [showStorageAccountModal, setShowStorageAccountModal] = useState<boolean>(false);
  const [selectedStorageAccount, setSelectedStorageAccount] = useState<any>({});

  const openStorageAccountModal = (storageAccount: any) => {
    setSelectedStorageAccount(storageAccount || {});
    setShowStorageAccountModal(true);
  }

  const closeStorageAccountModal = () => {
    setShowStorageAccountModal(false);
  }; 

  const [startDate, setStartDate] = useState<string>(commonService.getDate());
  const [endDate, setEndDate] = useState<string>(commonService.getDate());

  const reservationRetailPricePerYear = (vm: any, commitmentPeriodData: string) => {
    const reservationTerm = commitmentPeriodData === 'license1Year' ? '1 Year' : '3 Years';
    const retailPrice = (vm?.hardwareCommitent
      ?.reservationsPaygCost || []).find((x: any) => x.reservationTerm === reservationTerm)?.retailPrice || 0;
    return commitmentPeriodData === 'license1Year' ? retailPrice : retailPrice / 3;
  }

  const reservationPrice = (vm: any, commitmentPeriodData: string, timeFrameData: number) => {
    const price = commonService.fixDecimal(
      ((reservationRetailPricePerYear(vm, commitmentPeriodData) / 8640) * timeFrameData)
    );
    return price;
  }

  const hardwareCurrentPrice = (vm: any, timeFrameData: number) => {
    return commonService.fixDecimal(
      (vm?.hardwareCommitent?.hardwarePaygCost
        ?.retailPrice || 0) * timeFrameData
    )
  }
 
  const hardwareSavings = (vm: any, commitmentPeriodData: string, timeFrameData: number) => {
    const savings = parseFloat(hardwareCurrentPrice(vm, timeFrameData)) - parseFloat(reservationPrice(vm, commitmentPeriodData, timeFrameData));
    return parseFloat(commonService.fixDecimal(savings));
  }

  const breakEvenRunTimePercentage = (vm: any, commitmentPeriodData: string) => {
    const reservationTerm = commitmentPeriodData === 'license1Year' ? '1 Year' : '3 Years';
    const breakEvenRunTime = (vm?.hardwareCommitent
      ?.reservationsPaygCost || []).find((x: any) => x.reservationTerm === reservationTerm)?.breakEvenRunTimePercentage || 0;
    return commonService.fixDecimal(breakEvenRunTime * 100);
  }

  const filterList = [
    { name: 'Reservation', totalCost: 0, totalSavings: 0, count: 0, selected: false },
    { name: 'License', totalCost: 0, totalSavings: 0, count: 0, selected: false }
  ]
  const [headFilters, setHeadFilters] = useState<any[]>([]);

  const setTopFilters = (currentVMdata: any, timeFrameData: number, commitmentPeriodData: string) => {
    let totalReservationCost = 0;
    let totalReservationSavings = 0;
    let totalReservationCount = 0;
    let totalLicenseCost = 0;
    let totalLicenseSavings = 0;
    let totalLicenseCount = 0;
    currentVMdata.forEach((x: any) => {
      const reservationBreakEven = parseFloat(breakEvenRunTimePercentage(x, commitmentPeriodData));

      const licenseBreakEven = parseFloat(commonService.fixDecimal(((x?.hybridBenefis ?? {})[commitmentPeriodData]?.breakEvenRunTimePercentage || 0) * 100));

      const runTime = getRunTimeHoursPercentage(x);
      if(reservationBreakEven < runTime) {
        totalReservationCost += parseFloat(reservationPrice(x, commitmentPeriodData, timeFrameData) || '0');
        totalReservationSavings += hardwareSavings(x, commitmentPeriodData, timeFrameData);
        totalReservationCount++;
      }
      if(licenseBreakEven < runTime && !x?.hybridBenefis?.currentSetting && 
        !(x?.hybridBenefis?.isHbuWindowsCandidate ===
                                  "No" || x?.hybridBenefis?.isHbuWindowsCandidate ===
                                  "Undefined" ||
                                !x?.hybridBenefis?.isHbuWindowsCandidate)) {
        totalLicenseCost += parseFloat(commonService.fixDecimal((x?.hybridBenefis?.osPaygCost || 0) * timeFrameData));
        totalLicenseSavings += parseFloat(commonService.fixDecimal(((x?.hybridBenefis ?? {})[commitmentPeriodData]?.savingsPerHour || 0) * timeFrameData));
        totalLicenseCount++;
      }

    });
    filterList[0].totalCost = parseFloat(commonService.fixDecimal(totalReservationCost));
    filterList[0].totalSavings = parseFloat(commonService.fixDecimal(totalReservationSavings));
    filterList[0].count = totalReservationCount;
    filterList[1].totalCost = parseFloat(commonService.fixDecimal(totalLicenseCost));
    filterList[1].totalSavings = parseFloat(commonService.fixDecimal(totalLicenseSavings));
    filterList[1].count = totalLicenseCount;
    setHeadFilters(filterList);
  }

  const selectFilter = (i: number) => {
    const filters = JSON.parse(JSON.stringify(headFilters));
    filters[i].selected = !filters[i].selected;
    setHeadFilters(filters);
    filterVM(filterData, subscription, resourceGroup, filters);
  }

  const checkbox = useRef<any>(null);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedResource, setSelectedResource] = useState<
    OpportunityPlanResourceItem[]
  >([]);

  const toggleAll = () => {
    setSelectedResource(checked || indeterminate ? [] : vmFilteredData || []);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const [templates, setTemplates] = useState<
    OpportunityPlanResourceTaskTemplateItem[]
  >([]);

  const getPlanResourceTaskTemplate = () => {
    setTemplates([]);
    OpportunityService.getPlanResourceTaskTemplate()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setTemplates(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const [showCloudResourceModal, setShowCloudResourceModal] = useState<boolean>(false);
  const [selectedResources, setSelectedResources] = useState<any[]>([]);

  const openCloudResourceModal = () => {
    setSelectedResources(selectedResource || []);
    setShowCloudResourceModal(true);
  }

  const closeCloudResourceModal = (refresh?: boolean) => {
    setShowCloudResourceModal(false);
    if(refresh) {
      getFinOpsSummaryVMs(lookBackPeriod);
      setSelectedResource([]);
    }
  }

  const [showStorageCostBreakdownModal, setShowStorageCostBreakdownModal] = useState<boolean>(false);

  const openStorageCostBreakdownModal = (storageAccount: any) => {
    setSelectedStorageAccount(storageAccount || {});
    setShowStorageCostBreakdownModal(true);
  }

  const closeStorageCostBreakdownModal = () => {
    setShowStorageCostBreakdownModal(false);
  }; 

  const [subscriptionListForMonitorData, setSubscriptionListForMonitorData] = useState<Array<{name: string, id: string, rg: string[]}>>([]);
  const [subscriptionForMonitorData, setSubscriptionForMonitorData] = useState<string>(all);
  const [resourceGroupListForMonitorData, setResourceGroupListForMonitorData] = useState<string[]>([]);
  const [resourceGroupForMonitorData, setResourceGroupForMonitorData] = useState<string>(all);
  const [category, setCategory] = useState<string>(all);
  const [categoryData, setCategoryData] = useState<CategoryList[]>([]);
  const [monitorData, setMonitorData] = useState<AzureGraphResultsItem[]>([]);
  const [graphData, setGraphData] = useState<AzureGraphResultsItem[]>([]);

  const [showQueryInfoModal, setShowQueryInfoModal] = useState<boolean>(false);
  const [queryInfo, setQueryInfo] = useState<any>([]);

  const openQueryInfoModal = (info: any) => {
    setQueryInfo(info || {});
    setShowQueryInfoModal(true);
  }

  const closeQueryInfoModal = () => {
    setShowQueryInfoModal(false);
  }; 

  const [showSnapshotModal, setSnapshotModal] = useState<boolean>(false);
  const [selectedQuery, setSelectedQuery] = useState<AzureGraphResultsItem | any>({});

  const openSnapshotModal = (info: any) => {
    setSelectedQuery(info || {});
    setSnapshotModal(true);
  }

  const closeSnapshotModal = () => {
    setSnapshotModal(false);
  }; 

  const initCategory = (data: AzureGraphResultsItem[]) => {
    const categoryList: CategoryList[] = [];
    data.forEach((x) => {
      const category = categoryList.find((c) => c.id === x.category);
      if (!category) {
        categoryList.push({
          id: x.category,
          name: x.category,
          subscription: [
            {
              id: x.subscriptionId,
              name: x.subscriptionName,
              rg: [
                x.resourceGroup
              ],
            },
          ],
          count: 1
        });
      } else {
        (category as any).count++;
        const subscription = (category.subscription || []).find(s => s.id === x.subscriptionId);
        if(!subscription) {
          (category.subscription || []).push(
            {
              id: x.subscriptionId,
              name: x.subscriptionName,
              rg: [
                x.resourceGroup
              ],
            }
          );
        } else {
          const rg = (subscription.rg || []).find(r => r === x.resourceGroup);
          if(!rg) {
            subscription.rg.push(x.resourceGroup);
          }
        }
      }
    });
    setCategoryData(categoryList);
    initCategoryList(data);
    filterMonitorData(data, all, all, all);
  };

  const initCategoryList = (data: AzureGraphResultsItem[]) => {
    const subscriptionList: any[] = [];
    const rgList: any[] = [];
    (data || []).forEach(x => {
      const i = subscriptionList.findIndex(s => s.id === x.subscriptionId);
      if(i === -1) {
        subscriptionList.push({ id: x.subscriptionId, name: x.subscriptionName, rg: [x.resourceGroup] })
      } else {
        
        const j = rgList.findIndex(r => r === x.resourceGroup);
      if(j === -1 && x.resourceGroup) {
        subscriptionList[i].rg.push(x.resourceGroup);
      }
      }
      const rgIndex = rgList.findIndex(r => r === x.resourceGroup);
      if(rgIndex === -1 && x.resourceGroup) {
        rgList.push(x.resourceGroup);
      }
    });
    setSubscriptionListForMonitorData(subscriptionList);
    setResourceGroupListForMonitorData(rgList);
  }

  const onChangeCategory = (e: any) => {
    setCategory(e?.target?.value);
    setSubscriptionForMonitorData(all);
    setResourceGroupForMonitorData(all);
    if(e?.target?.value === all) {
      initCategoryList(monitorData || []);
    } else {
      filterCategoryChange(e?.target?.value);
    }
    filterMonitorData(monitorData, e?.target?.value, all, all);
  }

  const parseRG = (rg: string[]): string[] => {
    const parseData = (rg || []).filter(r => r?.length > 0);
    return parseData;
  }

  const filterCategoryChange = (val: string) => {
    const selectedSubs = categoryData?.find(x => x.id === val)?.subscription || [];
    const subList: any[] = [];
    const rgList: any[] = [];
    selectedSubs.forEach(x => {
      subList.push(x);
      const parseRgData = parseRG(x.rg);
      rgList.push(...parseRgData);
    });
    setSubscriptionListForMonitorData(subList);
    setResourceGroupListForMonitorData(rgList);
  }

  const onChangeSubscriptionForMonitorData = (e: any) => {
    const rgList: any[] = [];
    setSubscriptionForMonitorData(e?.target?.value);
    setResourceGroupForMonitorData(all);
    if(e?.target?.value === all) {
      subscriptionListForMonitorData.forEach(x => {
        const parseRgData = parseRG(x.rg);
      rgList.push(...parseRgData);
      });
    } else {
      const rg = subscriptionListForMonitorData?.find(x => x.id === e?.target?.value)?.rg || [];
      if(rg?.length > 0) {
        const parseRgData = parseRG(rg);
        rgList.push(...parseRgData);
      }

    }
    setResourceGroupListForMonitorData(rgList);
    filterMonitorData(monitorData, category, e?.target?.value, all);
  }

  const onChangeResourceGroupForMonitorData = (e: any) => {
    setResourceGroupForMonitorData(e?.target?.value);
    filterMonitorData(monitorData, category, subscriptionForMonitorData, e?.target?.value);
  }

  const filterMonitorData = (data: AzureGraphResultsItem[], currentCategory: string, currentSub: string, currentRG: string) => {
    const filterMonitorData: AzureGraphResultsItem[] = [];
    const currentMonitorData: AzureGraphResultsItem[] = JSON.parse(JSON.stringify(data || []));
    currentMonitorData.forEach((x: AzureGraphResultsItem) => {
      if(((currentCategory === all || currentCategory === x.category) &&(currentSub === all || currentSub === x.subscriptionId)
       && (currentRG === all || currentRG === x.resourceGroup))
       ) {
        filterMonitorData.push(x);
      }
    });
    setGraphData(filterMonitorData);
  }

  const getListMonitorData = () => {
    setLoadingGraphData(true);
    CloudResourceService.getGraphQueryResult()
            .then((responseData: any) => {
              setLoadingGraphData(false);
              const result = responseData?.data?.result || [];
              setMonitorData(result);
              initCategory(result);
            })
            .catch((e: any) => {
              toast.error(`${e?.response?.data?.message}`);
              setLoadingGraphData(false);
            });
  };

  const getGraphMonitorFilterValue = () => {
    return {
      queryName: "",
      subscriptionName: "",
      type: "",
      location: "",
      resourceGroup: "",
      firstFoundOn: "",
      lastSeen: ""
    };
  };

  const [monitorFilterVal, setMonitorFilterVal] = useState<any>(getGraphMonitorFilterValue());

  const [monitorSortBy, setMonitorSortBy] = useState<string>("");
  const [monitorAsc, setMonitorAsc] = useState<boolean>(true);

  const onMonitorSortChange = (newSortBy: string, newAsc: boolean) => {
    setMonitorSortBy(newSortBy);
    setMonitorAsc(newAsc);
    sortMonitorData(graphData, newSortBy, newAsc);
  };

  const sortMonitorData = (
    data: AzureGraphResultsItem[],
    sortByName: string,
    ascVal: boolean
  ) => {
    const sortedData = (data || []).sort((a: any, b: any) => {
        const nameA = a[sortByName]?.toString()?.toLowerCase();
        const nameB = b[sortByName]?.toString()?.toLowerCase();
      if (!ascVal) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      }
    });
    setGraphData(sortedData);
  };

  const onMonitorFilterChange = (name: string, val: string) => {
    monitorFilterVal[name] = val;
    const filtered: AzureGraphResultsItem[] = [];
    (monitorData || [])?.forEach((x) => {
      if (
        (!monitorFilterVal?.queryName ||
          x.queryName
            ?.toString()
            ?.toLowerCase()
            .includes(
              monitorFilterVal?.queryName?.toString()?.toLowerCase()
            )) &&
        (!monitorFilterVal?.subscriptionName ||
          x.subscriptionName
            ?.toString()
            ?.toLowerCase()
            .includes(
              monitorFilterVal?.subscriptionName?.toString()?.toLowerCase()
            )) &&
        (!monitorFilterVal?.type ||
          x.type
            ?.toString()
            ?.toLowerCase()
            .includes(monitorFilterVal?.type?.toString()?.toLowerCase())) &&
        (!monitorFilterVal?.location ||
          x.location
            ?.toString()
            ?.toLowerCase()
            .includes(monitorFilterVal?.location?.toString()?.toLowerCase())) &&
        (!monitorFilterVal?.resourceGroup ||
          x.resourceGroup
            ?.toString()
            ?.toLowerCase()
            .includes(
              monitorFilterVal?.resourceGroup?.toString()?.toLowerCase()
            )) &&
        (!monitorFilterVal?.firstFoundOn ||
          new Date(x?.firstFoundOn)
            .toLocaleString()
            ?.toString()
            ?.toLowerCase()
            .includes(
              monitorFilterVal?.firstFoundOn?.toString()?.toLowerCase()
            )) &&
        (!monitorFilterVal?.lastSeen ||
          new Date(x?.lastSeen)
            .toLocaleString()
            ?.toString()
            ?.toLowerCase()
            .includes(monitorFilterVal?.lastSeen?.toString()?.toLowerCase()))
      ) {
        filtered.push(x);
      }
    });
    setGraphData(filtered);
  };



  const getStorageFilterValue = () => {
    return {
      name: "",
      finops: "",
      cost: ""
    };
  };

  const [storageFilterVal, setStorageFilterVal] = useState<any>(getStorageFilterValue());

  const [storageSortBy, setStorageSortBy] = useState<string>("");
  const [storageAsc, setStorageAsc] = useState<boolean>(true);

  const onStorageSortChange = (newSortBy: string, newAsc: boolean) => {
    setStorageSortBy(newSortBy);
    setStorageAsc(newAsc);
    sortStorageData(storageAccountsFilteredData, newSortBy, newAsc);
  };

  const sortStorageData = (
    data: any[],
    sortByName: string,
    ascVal: boolean
  ) => {
    const sortedData = (data || []).sort((a: any, b: any) => {
        let nameA;
        let nameB;
        if(sortByName === 'name') {
          nameA = a?.resourceData?.name?.toString()?.toLowerCase() || '';
          nameB = b.resourceData?.name?.toString()?.toLowerCase() || '';
        } else if(sortByName === 'finops') {
          nameA = a?.errors?.length || 0;
          nameB = b?.errors?.length || 0;
        } else if(sortByName === 'cost') {
          nameA = a?.costVal || 0;
          nameB = b?.costVal || 0;
        } else {
          nameA = a[sortByName]?.toString()?.toLowerCase();
          nameB = b[sortByName]?.toString()?.toLowerCase();
        }
      if (!ascVal) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      }
    });
    setStorageAccountsFilteredData(sortedData);
  };

  const onStorageFilterChange = (name: string, val: string) => {
    storageFilterVal[name] = val;
    const filtered: any[] = [];
    storageAccounts?.forEach((x) => {
      if (
        (!storageFilterVal?.name ||
          x.resourceData?.name
            ?.toString()
            ?.toLowerCase()
            .includes(
              storageFilterVal?.name?.toString()?.toLowerCase()
            )) &&
        (!storageFilterVal?.finops ||
          x.subscriptionName
            ?.toString()
            ?.toLowerCase()
            .includes(
              `Errors: ${storageFilterVal?.errors?.length}`?.toString()?.toLowerCase()
            )) &&
        (!storageFilterVal?.cost ||
          x.costVal
            ?.toString()
            ?.toLowerCase()
            .includes(storageFilterVal?.cost?.toString()?.toLowerCase()))
      ) {
        filtered.push(x);
      }
    });
    setStorageAccountsFilteredData(filtered);
  };










  const [wasteFilterVal, setWasteFilterVal] = useState<any>(getStorageFilterValue());

  const [wasteSortBy, setWasteSortBy] = useState<string>("");
  const [wasteAsc, setWasteAsc] = useState<boolean>(true);

  const onWasteSortChange = (newSortBy: string, newAsc: boolean) => {
    setWasteSortBy(newSortBy);
    setWasteAsc(newAsc);
    sortWasteData(unattachedStorage, newSortBy, newAsc);
  };

  const sortWasteData = (
    data: any[],
    sortByName: string,
    ascVal: boolean
  ) => {
    const sortedData = (data || []).sort((a: any, b: any) => {
        let nameA;
        let nameB;
        if(sortByName === 'totalCost') {
          nameA = commonService.fixDecimal(a?.totalCost || 0) || '';
          nameB = commonService.fixDecimal(b?.totalCost || 0) || '';
        } else {
          nameA = a[sortByName]?.toString()?.toLowerCase();
          nameB = b[sortByName]?.toString()?.toLowerCase();
        }
      if (!ascVal) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      }
    });
    setUnattachedStorageDataFiltered(sortedData);
  };

  const onWasteFilterChange = (name: string, val: string) => {
    wasteFilterVal[name] = val;
    const filtered: any[] = [];
    unattachedStorage?.forEach((x) => {
      if (
        (!wasteFilterVal?.name ||
          x.name
            ?.toString()
            ?.toLowerCase()
            .includes(
              wasteFilterVal?.name?.toString()?.toLowerCase()
            ))&&
            (!wasteFilterVal?.location ||
              x.location
                ?.toString()
                ?.toLowerCase()
                .includes(
                  wasteFilterVal?.location?.toString()?.toLowerCase()
                ))&&
                (!wasteFilterVal?.resourceGroup ||
                  x.resourceGroup
                    ?.toString()
                    ?.toLowerCase()
                    .includes(
                      wasteFilterVal?.resourceGroup?.toString()?.toLowerCase()
                    ))&&
                    (!wasteFilterVal?.skuName ||
                      x.skuName
                        ?.toString()
                        ?.toLowerCase()
                        .includes(
                          wasteFilterVal?.skuName?.toString()?.toLowerCase()
                        ))&&
                        (!wasteFilterVal?.skuSize ||
                          x.skuSize
                            ?.toString()
                            ?.toLowerCase()
                            .includes(
                              wasteFilterVal?.skuSize?.toString()?.toLowerCase()
                            ))&&
                            (!wasteFilterVal?.totalCost ||
                              commonService.fixDecimal(x?.totalCost || 0)
                                ?.toString()
                                ?.toLowerCase()
                                .includes(
                                  wasteFilterVal?.totalCost?.toString()?.toLowerCase()
                                ))
        
      ) {
        filtered.push(x);
      }
    });
    setUnattachedStorageDataFiltered(filtered);
  };
  
  const checkFilters = (data: CloudResourceFilter) => {
    const isExist = (Object.keys(data || {}).some(x => (data as any)[x].filter));
    setHasFilter(isExist);
  }

  const removeFilter = (key: string) => {
    const currentFilterItem = (filterData as any)[key];
    if(currentFilterItem?.type === 'value') {
      (filterData as any)[key].value = '';
    } else {
      (filterData as any)[key].from = 0;
      (filterData as any)[key].to = (filterData as any)[key].max;
    }
    (filterData as any)[key].filter = false;
    checkFilters(filterData);
    filterVM(filterData, subscription, resourceGroup, headFilters, true);
  }

  const [showComplianceModal, setShowComplianceModal] = useState<boolean>(false);
  const [complianceModalData, setComplianceModalData] = useState<any[]>([]);

  const openComplianceModal = (data: any[]) => {
    setComplianceModalData(data || []);
    setShowComplianceModal(true);
  }

  const closeComplianceModal = () => {
    setShowComplianceModal(false);
  }; 

  const [showProtectedVmModal, setShowProtectedVmModal] = useState<boolean>(false);
  const [protectedVmData, setProtectedVm] = useState<any[]>([]);

  const openProtectedVmModal = (data: AzureResourceDetectProtectedVmItemDetail[]) => {
    setProtectedVm(data || []);
    setShowProtectedVmModal(true);
  }

  const closeProtectedVmModal = () => {
    setShowProtectedVmModal(false);
  }; 


  return (
    <>
      {showCloudResourceModal && (
        <CloudResourcesModal
          show={showCloudResourceModal}
          closeModal={closeCloudResourceModal}
          templates={templates || []}
          resources={selectedResources}
        />
      )}

      {showStorageCostBreakdownModal && (
        <StorageCostBreakDownModal
          show={showStorageCostBreakdownModal}
          closeModal={closeStorageCostBreakdownModal}
          storageAccount={selectedStorageAccount || {}}
        />
      )}
      {showStorageAccountModal && (
        <StorageAccountModal
          show={showStorageAccountModal}
          closeModal={closeStorageAccountModal}
          data={selectedStorageAccount || {}}
        />
      )}
      {showInvoiceInfoModal && (
        <UnattachedStorageInvoiceModal
          show={showInvoiceInfoModal}
          closeModal={closeInvoiceInfoModal}
          invoiceData={invoiceInfo || []}
        />
      )}
      {showVMDetailModal && (
        <VMDetailModal
          show={showVMDetailModal}
          closeModal={closeVMDetailModal}
          resourceId={selectedResourceId || ""}
        />
      )}
      {showVMRightSizeModal && (
        <VMRightSizeModal
          show={showVMRightSizeModal}
          closeModal={closeVMRightSizeModal}
          resourceId={selectedResourceId || ""}
          currentSize={size}
          location={location}
        />
      )}
      {showFilterModal && (
        <FilterModal
          show={showFilterModal}
          closeModal={closeFilterModal}
          filterData={filterData}
          refreshDetails={refreshDetails}
          resourceData={vmData}
          clearFilters={clearFilters}
        />
      )}
      {showQueryInfoModal && (
        <JSONViewModal
          show={showQueryInfoModal}
          closeModal={closeQueryInfoModal}
          data={queryInfo || {}}
          modalTitle='Additional Info'
          codeTitle='Info'
        />
      )}

      {showSnapshotModal && (
        <CloudResourceSnapshotModal
          show={showSnapshotModal}
          closeModal={closeSnapshotModal}
          data={selectedQuery || {}}
        />
      )}

      {showComplianceModal && (
        <ComplianceModal
          show={showComplianceModal}
          closeModal={closeComplianceModal}
          complianceData={complianceModalData || []}
        />
      )}

      {showProtectedVmModal && (
        <ProtectedVmModal
          show={showProtectedVmModal}
          closeModal={closeProtectedVmModal}
          protectedVmData={protectedVmData || []}
        />
      )}

      <div className="py-2 mx-auto px-8 sm:px-6 lg:px-4">
        <TabsView
          tabs={tabs}
          currentTabSelection={currentTabSelection}
          setCurrentTabSelection={setCurrentTabSelection}
        />

        <div
          className={commonService.classNames(
            currentTabSelection !== "Virtual Machine" ? "hidden" : "",
            "my-4 px-4"
          )}
        >
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto px-8">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Virtual Machine
              </h1>
            </div>
          </div>
          <div className="flow-root">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-2">
                {loading ? (
                  <Pulse show={loading} />
                ) : (
                  <>
                    <div className="flex items-center">
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="lookBackPeriod"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Loopback Period
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="lookBackPeriod"
                            name="lookBackPeriod"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={lookBackPeriod}
                            onChange={(e) => {
                              onChangeLookback(e);
                            }}
                          >
                            {lookBackList.map((range: number) => (
                              <option value={range} key={range}>
                                {range}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="TimeFrame"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Time Frame
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="TimeFrame"
                            name="TimeFrame"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={timeFrame}
                            onChange={(e) => {
                              onChangeTimeFrame(e);
                            }}
                          >
                            {timeFrameList.map((time: any) => (
                              <option value={time.value} key={time.value}>
                                {time.timeFrame}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="CommitmentPeriod"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Commitment Periods
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="CommitmentPeriod"
                            name="CommitmentPeriod"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={commitmentPeriod}
                            onChange={(e) => {
                              onChangeCommitmentPeriod(e);
                            }}
                          >
                            {commitmentPeriodsList.map((period: any) => (
                              <option value={period.value} key={period.value}>
                                {period.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="Subscriptions"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Subscriptions
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="Subscriptions"
                            name="Subscriptions"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={subscription}
                            onChange={(e) => {
                              onChangeSubscription(e);
                            }}
                          >
                            {subscriptionList.map(
                              (sub: {
                                subscriptionName: string;
                                subscriptionId: string;
                                rgList: string[];
                              }) => (
                                <option
                                  value={sub?.subscriptionId}
                                  key={sub?.subscriptionId}
                                >
                                  {sub?.subscriptionName}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="rsGroup"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          RG
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="rsGroup"
                            name="rsGroup"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={resourceGroup}
                            onChange={(e) => {
                              onChangeResourceGroup(e);
                            }}
                          >
                            {resourceGroupList.map((rg: string) => (
                              <option value={rg} key={rg}>
                                {rg}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <button
                          type="button"
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => openFilterModal()}
                        >
                          Filter
                        </button>
                      </div>
                      {hasFilter && (
                        <div className="sm:px-6 lg:px-6">
                          <button
                            type="button"
                            className="block rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => clearFilters()}
                          >
                            Clear
                          </button>
                        </div>
                      )}
                      {selectedResource?.length > 0 && (
                        <div className="sm:px-6 lg:px-6">
                          <button
                            type="button"
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => openCloudResourceModal()}
                          >
                            Resource
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="mb-8 ml-6">
                      <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                        {headFilters.map((project, i) => (
                          <li
                            key={project.name}
                            className="cursor-pointer col-span-1 flex rounded-md shadow-sm"
                            onClick={() => selectFilter(i)}
                          >
                            <div
                              className={commonService.classNames(
                                "flex flex-1 items-center justify-between truncate rounded-md border bg-white border-2",
                                project.selected
                                  ? "border-indigo-600"
                                  : "border-gray-200"
                              )}
                            >
                              <div className="flex-1 truncate px-4 py-2 text-sm">
                                <a
                                  href="#"
                                  className="font-medium text-gray-900 hover:text-gray-600"
                                >
                                  {project.name}
                                </a>
                                <p className="text-gray-500">
                                  Total Count: {project.count} <br />
                                  Total Cost: ${project.totalCost} <br />
                                  Total Savings: ${project.totalSavings}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {hasFilter && (
                      <div className="bg-gray-100">
                        <div className="mx-auto px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
                          <h3 className="text-sm font-medium text-gray-500">
                            Filters
                            <span className="sr-only">, active</span>
                          </h3>

                          <div
                            aria-hidden="true"
                            className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"
                          />

                          <div className="mt-2 sm:ml-4 sm:mt-0">
                            <div className="-m-1 flex flex-wrap items-center">
                              {Object.keys(filterData).map((key: string) => (
                                <>
                                  {(filterData as any)[key]?.filter && (
                                    <span
                                      key={key}
                                      className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                                    >
                                      <span>
                                        {(filterData as any)[key]?.displayName}
                                      </span>
                                      <button
                                        type="button"
                                        className="ml-1 inline-flex size-4 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                                      >
                                        <span className="sr-only">
                                          Remove filter for {key}
                                        </span>
                                        <XMarkIcon
                                          className="block h-3 w-3 stroke-[3px]"
                                          aria-hidden="true"
                                          onClick={() => removeFilter(key)}
                                        />
                                      </button>
                                    </span>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {loadingTable ? (
                      <Pulse show={loadingTable} />
                    ) : (
                      <>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="relative px-7 sm:w-12 sm:px-6"
                            >
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                              />
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                Name
                                <SortTableHeader
                                  current={
                                    isSorted("name") ||
                                    isSorted("location") ||
                                    isSorted("size") ||
                                    isSorted("operativeSystem")
                                  }
                                  asc={asc}
                                  sortList={nameSortList || []}
                                  sortBy={sortBy}
                                  sortClick={sortClick}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                RunTime Hours
                                <SortTableHeader
                                  current={
                                    isSorted("percentage") ||
                                    isSorted("recommendations") ||
                                    isSorted("errors")
                                  }
                                  asc={asc}
                                  sortList={runTimeList || []}
                                  sortBy={sortBy}
                                  sortClick={sortClick}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                Hardware
                                <SortTableHeader
                                  current={
                                    isSorted("hardwareCurrent") ||
                                    isSorted("hardwareResSavings")
                                  }
                                  asc={asc}
                                  sortList={hardwareList || []}
                                  sortBy={sortBy}
                                  sortClick={sortClick}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                Licensing
                                <SortTableHeader
                                  current={
                                    isSorted("linenseCurrent") ||
                                    isSorted("lincensePurSavings")
                                  }
                                  asc={asc}
                                  sortList={licenseList || []}
                                  sortBy={sortBy}
                                  sortClick={sortClick}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                Disk
                                <SortTableHeader
                                  current={
                                    isSorted("diskType") || isSorted("diskSize")
                                  }
                                  asc={asc}
                                  sortList={diskList || []}
                                  sortBy={sortBy}
                                  sortClick={sortClick}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                Disk Cost
                                <TableSort
                                  sortBy={sortBy}
                                  sortName={"diskCost"}
                                  asc={asc}
                                  onSortChange={sortClick}
                                />
                              </div>
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                              FinOps
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                              Governance
                              </div>
                            </th>
                          </tr>
                        </thead>
                        {(vmFilteredData || [])?.length > 0 && (
                          <tbody className="divide-y divide-gray-200">
                            {(vmFilteredData || [])?.map((vm, i: number) => (
                              <tr
                                key={vm.id}
                                className={
                                  selectedResource.includes(vm)
                                    ? "bg-gray-50"
                                    : undefined
                                }
                              >
                                <td className="relative px-7 sm:w-12 sm:px-6">
                                  {selectedResource.includes(vm) && (
                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                  )}
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    value={vm.id}
                                    checked={selectedResource.includes(vm)}
                                    onChange={(e) =>
                                      setSelectedResource(
                                        e.target.checked
                                          ? [...selectedResource, vm]
                                          : selectedResource.filter(
                                              (p) => p !== vm
                                            )
                                      )
                                    }
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                  <div>
                                    <div className="flex items-center">
                                      <a
                                        href="#"
                                        className="truncate font-medium text-gray-900"
                                        onClick={() =>
                                          openVMDetailModal(vm.resourceId)
                                        }
                                      >
                                        {vm.name}
                                      </a>

                                      <ResourceLink
                                        link={vm?.resourceLink}
                                        index={i}
                                      />
                                    </div>
                                    {vm.armLocation}
                                    <br />
                                    <a
                                      href="#"
                                      className="truncate text-gray-900"
                                      onClick={() => openVMRightSizeModal(vm)}
                                    >
                                      {commonService.sizePipe(vm.size || "")}
                                    </a>
                                    <br />
                                    {vm.operativeSystem}
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                                    <div
                                      className="bg-indigo-600 h-2.5 rounded-full"
                                      style={{
                                        width: `${commonService.fixDecimal(
                                          ((vm.runTimeHours || 0) /
                                            (vm.lookBackPeriodHours || 0)) *
                                            100 || 0
                                        )}%`,
                                      }}
                                    ></div>
                                  </div>
                                  {`${commonService.fixDecimal(
                                    vm.runTimeHours || 0
                                  )}/${
                                    vm.lookBackPeriodHours
                                  } (${getRunTimeHoursPercentage(vm)}%)`}
                                  <RecommendationsTooltip
                                    index={i}
                                    recommendations={vm?.recommendations || []}
                                  />
                                  <ErrorsTooltip
                                    index={i}
                                    errors={vm?.errors || []}
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                  <div>
                                    Current: $
                                    {hardwareCurrentPrice(vm, timeFrame)} <br />
                                    {is1Year() ? "1Y" : "3Y"} Res:
                                    <span>
                                      $
                                      {reservationPrice(
                                        vm,
                                        commitmentPeriod,
                                        timeFrame
                                      )}
                                    </span>{" "}
                                    SAV:
                                    <span
                                      className={commonService.classNames(
                                        "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
                                        hardwareSavings(
                                          vm,
                                          commitmentPeriod,
                                          timeFrame
                                        ) > 0
                                          ? "bg-green-50 text-green-700 ring-green-600/20"
                                          : "bg-blue-50 text-blue-700 ring-blue-700/10"
                                      )}
                                    >
                                      {hardwareSavings(
                                        vm,
                                        commitmentPeriod,
                                        timeFrame
                                      )}
                                    </span>{" "}
                                    BE:
                                    <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                      {breakEvenRunTimePercentage(
                                        vm,
                                        commitmentPeriod
                                      )}
                                      %
                                    </span>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                  <div>
                                    {vm?.hybridBenefis
                                      ?.isHbuWindowsCandidate === "No" ||
                                    vm?.hybridBenefis?.isHbuWindowsCandidate ===
                                      "Undefined" ||
                                    !vm?.hybridBenefis
                                      ?.isHbuWindowsCandidate ? (
                                      "NA"
                                    ) : (
                                      <>
                                        {vm?.hybridBenefis?.currentSetting ? (
                                          <>
                                            Current: $
                                            {commonService.fixDecimal(
                                              (vm?.hybridBenefis?.osPaygCost ||
                                                0) * timeFrame
                                            )}{" "}
                                            ({vm?.hybridBenefis?.currentSetting}
                                            )
                                          </>
                                        ) : (
                                          <dd
                                            data-tooltip-id={
                                              "-description-tooltip" + i
                                            }
                                          >
                                            <Tooltip
                                              id={"-description-tooltip" + i}
                                              arrowColor="transparent"
                                              place="bottom"
                                            >
                                              <div style={{}}>
                                                <span>
                                                  Purchase License:{" "}
                                                  {
                                                    (vm?.hybridBenefis || {})[
                                                      commitmentPeriod
                                                    ]?.licensesCommitment
                                                      ?.productTitle
                                                  }
                                                </span>
                                                <br />
                                                <span>
                                                  Term:{" "}
                                                  {
                                                    (vm?.hybridBenefis || {})[
                                                      commitmentPeriod
                                                    ]?.licensesCommitment
                                                      ?.termDuration
                                                  }
                                                </span>
                                                <br />
                                                <span>
                                                  Cores Per License:{" "}
                                                  {
                                                    (vm?.hybridBenefis || {})[
                                                      commitmentPeriod
                                                    ]?.licensesCommitment
                                                      ?.coresMax
                                                  }
                                                </span>
                                                <br />
                                                <span>
                                                  Licenses Needed:{" "}
                                                  {
                                                    (vm?.hybridBenefis || {})[
                                                      commitmentPeriod
                                                    ]?.licenseNeededCount
                                                  }
                                                </span>
                                                <br />
                                                <span>
                                                  Payment Schedule:{" "}
                                                  {
                                                    (vm?.hybridBenefis || {})[
                                                      commitmentPeriod
                                                    ]?.licensesCommitment
                                                      ?.billingPlan
                                                  }
                                                </span>
                                                <br />
                                                <span>
                                                  Total Cost:{" "}
                                                  {((vm?.hybridBenefis || {})[
                                                    commitmentPeriod
                                                  ]?.licenseNeededCount || 0) *
                                                    ((vm?.hybridBenefis || {})[
                                                      commitmentPeriod
                                                    ]?.licensesCommitment
                                                      ?.erp || 0)}
                                                </span>
                                              </div>
                                            </Tooltip>
                                            Current: $
                                            {commonService.fixDecimal(
                                              (vm?.hybridBenefis?.osPaygCost ||
                                                0) * timeFrame
                                            )}{" "}
                                            <br />
                                            {is1Year() ? "1Y" : "3Y"} Pur: $
                                            {commonService.fixDecimal(
                                              ((vm?.hybridBenefis || {})[
                                                commitmentPeriod
                                              ]?.licenseNeededPricePerHour ||
                                                0) * timeFrame
                                            )}{" "}
                                            SAV:
                                            <span
                                              className={commonService.classNames(
                                                "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
                                                parseFloat(
                                                  commonService.fixDecimal(
                                                    (vm?.hybridBenefis || {})[
                                                      commitmentPeriod
                                                    ]?.savingsPerHour || 0
                                                  )
                                                ) > 0
                                                  ? "bg-green-50 text-green-700 ring-green-600/20"
                                                  : "bg-blue-50 text-blue-700 ring-blue-700/10"
                                              )}
                                            >
                                              {commonService.fixDecimal(
                                                ((vm?.hybridBenefis || {})[
                                                  commitmentPeriod
                                                ]?.savingsPerHour || 0) *
                                                  timeFrame
                                              )}
                                            </span>{" "}
                                            BE:
                                            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                              {commonService.fixDecimal(
                                                ((vm?.hybridBenefis || {})[
                                                  commitmentPeriod
                                                ]?.breakEvenRunTimePercentage ||
                                                  0) * 100
                                              )}
                                              %
                                            </span>
                                          </dd>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                  {(!filterData?.diskType?.value ||
                                    filterData?.diskType?.value === "os") && (
                                    <dd data-tooltip-id={"-osDisk-tooltip" + i}>
                                      <span className="flex items-center">
                                        Os Disk -{" "}
                                        {commonService.sizePipe(
                                          vm?.disk?.osDisk?.sku || ""
                                        )}{" "}
                                        {vm?.disk?.osDisk?.size} GB
                                        {(
                                          vm?.disk?.osDisk?.recommendations ||
                                          []
                                        ).length > 0 && (
                                          <InformationCircleIcon
                                            aria-hidden="true"
                                            className="w-4 h-4 ml-1"
                                          />
                                        )}
                                      </span>
                                      {(vm?.disk?.osDisk?.recommendations || [])
                                        .length > 0 && (
                                        <Tooltip
                                          id={"-osDisk-tooltip" + i}
                                          arrowColor="transparent"
                                          place="bottom"
                                        >
                                          <div style={{}}>
                                            {(
                                              vm?.disk?.osDisk
                                                ?.recommendations || []
                                            ).map(
                                              (osDiskRecommendation: any) => (
                                                <>
                                                  <span>
                                                    Summary:{" "}
                                                    {
                                                      osDiskRecommendation?.summary
                                                    }
                                                  </span>{" "}
                                                  <br />
                                                  <span>
                                                    Severity:{" "}
                                                    {
                                                      osDiskRecommendation?.severity
                                                    }
                                                  </span>{" "}
                                                  <br />
                                                  <hr />
                                                </>
                                              )
                                            )}
                                          </div>
                                        </Tooltip>
                                      )}
                                    </dd>
                                  )}
                                  {(!filterData?.diskType?.value ||
                                    filterData?.diskType?.value === "data") &&
                                    (vm?.disk?.dataDisks || []).map(
                                      (disk: any, d: number) => (
                                        <dd
                                          data-tooltip-id={
                                            "-dataDisk-tooltip" + i
                                          }
                                          key={"Data Disk" + d}
                                        >
                                          <span className="flex items-center">
                                            Data Disk{d + 1} -{" "}
                                            {commonService.sizePipe(
                                              disk?.sku || ""
                                            )}{" "}
                                            {disk?.size} GB
                                            {(disk?.recommendations || [])
                                              .length > 0 && (
                                              <InformationCircleIcon
                                                aria-hidden="true"
                                                className="w-4 h-4 ml-1"
                                              />
                                            )}
                                          </span>
                                          {(disk?.recommendations || [])
                                            ?.length > 0 && (
                                            <Tooltip
                                              id={"-dataDisk-tooltip" + i}
                                              arrowColor="transparent"
                                              place="bottom"
                                            >
                                              <div style={{}}>
                                                {(
                                                  disk?.recommendations || []
                                                ).map(
                                                  (
                                                    dataDiskRecommendation: any
                                                  ) => (
                                                    <>
                                                      <span>
                                                        Summary:{" "}
                                                        {
                                                          dataDiskRecommendation?.summary
                                                        }
                                                      </span>{" "}
                                                      <br />
                                                      <span>
                                                        Severity:{" "}
                                                        {
                                                          dataDiskRecommendation?.severity
                                                        }
                                                      </span>{" "}
                                                      <br />
                                                      <hr />
                                                    </>
                                                  )
                                                )}
                                              </div>
                                            </Tooltip>
                                          )}
                                        </dd>
                                      )
                                    )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                  { vm?.invoiceInfo?.length === 0 ? 
                                  <><InformationCircleIcon
                                      aria-hidden="true"
                                      className="w-4 h-4 ml-1"
                                      data-tooltip-id={"-vm-cost-tooltip" + i}
                                    />
                                  <Tooltip
                                    id={"-vm-cost-tooltip" + i}
                                    arrowColor="transparent"
                                    place="top"
                                  >
                                    <div style={{}}>
                                      No Data Found.
                                    </div>
                                  </Tooltip>
                                  </>:
                                  <>{commonService.getCurrencyValue(commonService.fixDecimal(vm?.diskCost || 0), vm.currencyCode)}</>}
                                  </td>

                                  <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                    { loadingCompliance ? <Spinner show={loadingCompliance} isPrimary={true} />: 
                                    <>
                                    {
                                      vm.complianceCount && vm.nonComplianceCount ?
                                      <div className="cursor-pointer" data-tooltip-id={"-vm-compliance-tooltip" + i} onClick={() => openComplianceModal(vm.complianceList)}>
                                        <span>Policy </span>
                                        <span className="text-green-700">{vm.complianceCount}</span>
                                        <span> | </span>
                                        <span className="text-red-700">{vm.nonComplianceCount}</span>
                                        <Tooltip
                                          id={"-vm-compliance-tooltip" + i}
                                          arrowColor="transparent"
                                          place="top"
                                        >
                                          <div style={{}} className="whitespace-pre-line">
                                            {vm.complianceTooltipText}
                                          </div>
                                        </Tooltip>
                                      </div>
                                      :
                                      <>
                                      <InformationCircleIcon
                                      aria-hidden="true"
                                      className="w-4 h-4 ml-1"
                                      data-tooltip-id={"-vm-compliance-empty-tooltip" + i}
                                    />
                                  <Tooltip
                                    id={"-vm-compliance-empty-tooltip" + i}
                                    arrowColor="transparent"
                                    place="top"
                                  >
                                    <div style={{}}>
                                      No Data Found.
                                    </div>
                                  </Tooltip>
                                      </>
                                    }
                                      
                                    </>
                                     }
                                    
                                  </td>

                                  <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                    { loadingProtectedVM ? <Spinner show={loadingProtectedVM} isPrimary={true} />: 
                                    <>
                                    {
                                      vm.protectionDetail?.length > 0 ?
                                      <div className="cursor-pointer" data-tooltip-id={"-protected-vm-tooltip" + i} onClick={() => openProtectedVmModal(vm.protectionDetail)}>
                                        <span>Azure Vault: </span>
                                        <span>{vm.protectionDetail[0]?.isProtected === "Yes" ? 'Protected': 'Protection Stopped'}</span>
                                        {vm.protectionDetail[0]?.lastBackupTime && 
                                        <>
                                        <span> | </span>
                                        <span>{new Date(vm.protectionDetail[0]?.lastBackupTime).toLocaleDateString()}</span>
                                        </>}
                                        <Tooltip
                                          id={"-protected-vm-tooltip" + i}
                                          arrowColor="transparent"
                                          place="top"
                                        >
                                          <div style={{
                                              maxWidth: "250px",
                                              overflow: "auto",
                                            }} className="whitespace-pre-line">
                                            {vm.protectedVaultTooltipText}
                                          </div>
                                        </Tooltip>
                                      </div>
                                      :
                                      <>Azure Vault: Not Protected</>
                                    }
                                      
                                    </>
                                     }
                                    
                                  </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                      {(vmFilteredData || []).length === 0 &&
                        <EmptyView />
                      }
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={commonService.classNames(
            currentTabSelection !== "Waste" ? "hidden" : "",
            "my-4 px-4"
          )}
        >
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto px-8">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Waste
              </h1>
            </div>
          </div>
          <div className="flow-root">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-2">
                {loadingUnattachedStorage ? (
                  <Pulse show={loadingUnattachedStorage} />
                ) : (
                  <>
                    <div className="flex items-end">
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="CloudResourcestartDate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Start Date
                        </label>
                        <div className="mt-2">
                          <input
                            id="CloudResourcestartDate"
                            name="CloudResourcestartDate"
                            type="date"
                            required
                            value={startDate}
                            onChange={(e) => setStartDate(e?.target?.value)}
                            className={commonService.classNames(
                              "w-72 mr-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              !startDate
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                      </div>
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="CloudResourceEndDate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          End Date
                        </label>
                        <div className="mt-2">
                          <input
                            id="CloudResourceEndDate"
                            name="CloudResourceEndDate"
                            type="date"
                            required
                            value={endDate}
                            onChange={(e) => setEndDate(e?.target?.value)}
                            className={commonService.classNames(
                              "w-72 mr-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              !endDate
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                      </div>
                      <div className="sm:px-6 lg:px-6">
                        <button
                          type="button"
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={getListUnattachedDisk}
                        >
                          Filter
                        </button>
                      </div>
                    </div>

                    <table className="min-w-full divide-y divide-gray-300 mt-2">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Name"
                              sortName="name"
                              sortBy={wasteSortBy}
                              asc={wasteAsc}
                              filterVal={wasteFilterVal.name}
                              onSortChange={onWasteSortChange}
                              onFilterChange={onWasteFilterChange}
                            />
                            {/* Name */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Location"
                              sortName="location"
                              sortBy={wasteSortBy}
                              asc={wasteAsc}
                              filterVal={wasteFilterVal.location}
                              onSortChange={onWasteSortChange}
                              onFilterChange={onWasteFilterChange}
                            />
                            {/* Location */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Resource Group"
                              sortName="resourceGroup"
                              sortBy={wasteSortBy}
                              asc={wasteAsc}
                              filterVal={wasteFilterVal.resourceGroup}
                              onSortChange={onWasteSortChange}
                              onFilterChange={onWasteFilterChange}
                            />
                            {/* Resource Group */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            {/* SKU */}
                            <TableHead
                              name="SKU"
                              sortName="skuName"
                              sortBy={wasteSortBy}
                              asc={wasteAsc}
                              filterVal={wasteFilterVal.skuName}
                              onSortChange={onWasteSortChange}
                              onFilterChange={onWasteFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Size"
                              sortName="skuSize"
                              sortBy={wasteSortBy}
                              asc={wasteAsc}
                              filterVal={wasteFilterVal.skuSize}
                              onSortChange={onWasteSortChange}
                              onFilterChange={onWasteFilterChange}
                            />
                            {/* Size */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Cost"
                              sortName="totalCost"
                              sortBy={wasteSortBy}
                              asc={wasteAsc}
                              filterVal={wasteFilterVal.totalCost}
                              onSortChange={onWasteSortChange}
                              onFilterChange={onWasteFilterChange}
                            />
                            {/* Cost */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Invoice Info
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200">
                        <>
                          {(unattachedStorageDataFiltered || []).length > 0 && (
                            <>
                              {(unattachedStorageDataFiltered || [])?.map(
                                (item, i) => (
                                  <tr key={item.id}>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                      <div className="flex items-center">
                                        <span>{item.name}</span>
                                        <ResourceLink
                                          link={item?.resourceLink}
                                          index={i}
                                        />
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                      {item.location}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                      {item.resourceGroup}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                      {item.skuName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                      {item.skuSize}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                      {item?.invoiceInfo?.length === 0 ? (
                                        <>
                                          <InformationCircleIcon
                                            aria-hidden="true"
                                            className="w-4 h-4 ml-1"
                                            data-tooltip-id={
                                              "-unattached-cost-tooltip" + i
                                            }
                                          />
                                          <Tooltip
                                            id={"-unattached-cost-tooltip" + i}
                                            arrowColor="transparent"
                                            place="top"
                                          >
                                            <div style={{}}>No Data Found.</div>
                                          </Tooltip>
                                        </>
                                      ) : (
                                        <>
                                          {commonService.getCurrencyValue(commonService.fixDecimal(item?.totalCost || 0), item.currencyCode)}
                                        </>
                                      )}
                                    </td>
                                    <td
                                      className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900"
                                      id="cloudResView"
                                    >
                                      <a
                                        href="#"
                                        className="text-indigo-600 hover:text-indigo-900 mr-3"
                                        onClick={() =>
                                          openInvoiceInfoModal(item)
                                        }
                                      >
                                        View
                                      </a>
                                    </td>
                                  </tr>
                                )
                              )}
                            </>
                          )}
                        </>
                      </tbody>
                    </table>
                    {(unattachedStorageDataFiltered || []).length === 0 &&
                      <EmptyView />
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          id="cloudResStorageSec"
          className={commonService.classNames(
            currentTabSelection !== "Storage" ? "hidden" : "",
            "my-4 px-4"
          )}
        >
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto px-8">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Storage
              </h1>
            </div>
          </div>
          <div className="flow-root">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-2">
                {loadingStorageAccount ? (
                  <Pulse show={loadingStorageAccount} />
                ) : (
                  <>
                    <div className="flex items-center">
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="lookBackPeriodForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Loopback Period
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="lookBackPeriodForStorage"
                            name="lookBackPeriodForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={lookBackPeriodForStorage}
                            onChange={(e) => {
                              onChangeLookbackForStorage(e);
                            }}
                          >
                            {lookBackList.map((range: number) => (
                              <option value={range} key={range}>
                                {range}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="timeFrameForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Time Frame
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="timeFrameForStorage"
                            name="timeFrameForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={timeFrameForStorage}
                            onChange={(e) => {
                              onChangeTimeFrameForStorage(e);
                            }}
                          >
                            {timeFrameList.map((time: any) => (
                              <option value={time.value} key={time.value}>
                                {time.timeFrame}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="commitmentPeriodForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Commitment Periods
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="commitmentPeriodForStorage"
                            name="commitmentPeriodForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={commitmentPeriodForStorage}
                            onChange={(e) => {
                              onChangeCommitmentPeriodForStorage(e);
                            }}
                          >
                            {commitmentPeriodsList.map((period: any) => (
                              <option value={period.value} key={period.value}>
                                {period.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="subscriptionForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Subscriptions
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="subscriptionForStorage"
                            name="subscriptionForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={subscriptionForStorage}
                            onChange={(e) => {
                              onChangeSubscriptionForStorage(e);
                            }}
                          >
                            {subscriptionListForStorage.map(
                              (sub: {
                                subscriptionName: string;
                                subscriptionId: string;
                                rgList: string[];
                              }) => (
                                <option
                                  value={sub?.subscriptionId}
                                  key={sub?.subscriptionId}
                                >
                                  {sub?.subscriptionId}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="resourceGroupForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          RG
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="resourceGroupForStorage"
                            name="resourceGroupForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={resourceGroupForStorage}
                            onChange={(e) => {
                              onChangeResourceGroupForStorage(e);
                            }}
                          >
                            {resourceGroupListForStorage.map((rg: string) => (
                              <option value={rg} key={rg}>
                                {rg}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <table className="min-w-full divide-y divide-gray-300 mt-2">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Name"
                              sortName="name"
                              sortBy={storageSortBy}
                              asc={storageAsc}
                              filterVal={storageFilterVal.name}
                              onSortChange={onStorageSortChange}
                              onFilterChange={onStorageFilterChange}
                            />
                            {/* Name */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Finops"
                              sortName="finops"
                              sortBy={storageSortBy}
                              asc={storageAsc}
                              filterVal={storageFilterVal.finops}
                              onSortChange={onStorageSortChange}
                              onFilterChange={onStorageFilterChange}
                            />
                            {/* Finops */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Cost"
                              sortName="cost"
                              sortBy={storageSortBy}
                              asc={storageAsc}
                              filterVal={storageFilterVal.cost}
                              onSortChange={onStorageSortChange}
                              onFilterChange={onStorageFilterChange}
                            />
                            {/* Cost */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Invoice Info
                          </th>
                        </tr>
                      </thead>
                      {(storageAccountsFilteredData || [])?.length > 0 && (
                        <tbody className="divide-y divide-gray-200">
                          {(storageAccountsFilteredData || [])?.map(
                            (item, i) => (
                              <tr key={item.id}>
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              <div className="flex items-center">
                                <a
                                  href="#"
                                  className="truncate font-medium text-gray-900"
                                  onClick={() => openStorageAccountModal(item)}
                                >
                                  {item?.resourceData?.name}
                                </a>
                                <ResourceLink
                                  link={item?.resourceLink}
                                  index={i}
                                />
                              </div>
                            </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                  <p>Recommendations (0)</p>
                                  {/* <p>Errors ({(item?.errors || []).length})</p> */}
                                  <ErrorsTooltip
                                    index={i}
                                    errors={item?.errors || []}
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                  <a
                                    className="flex items-center cursor-pointer"
                                    data-tooltip-id={
                                      "-storage-cost-tooltip" + i
                                    }
                                    href="#"
                                    onClick={() =>
                                      openStorageCostBreakdownModal(item)
                                    }
                                  >
                                    ${item.costVal}
                                    <InformationCircleIcon
                                      aria-hidden="true"
                                      className="w-4 h-4 ml-1"
                                    />
                                  </a>
                                  <Tooltip
                                    id={"-storage-cost-tooltip" + i}
                                    arrowColor="transparent"
                                    place="bottom"
                                  >
                                    <div style={{}}>
                                      {(Object.keys(item.tooltipVal) || []).map(
                                        (tooltipVal: any) => (
                                          <>
                                            <span>
                                              {tooltipVal}: $
                                              {commonService.fixDecimal(
                                                item.tooltipVal[tooltipVal] || 0
                                              )}
                                            </span>{" "}
                                            <br />
                                          </>
                                        )
                                      )}
                                    </div>
                                  </Tooltip>
                                </td>
                                <td
                                  className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900"
                                  id="cloudResInvoiceView"
                                >
                                  <a
                                    href="#"
                                    className="text-indigo-600 hover:text-indigo-900 mr-3"
                                    onClick={() => openInvoiceInfoModal(item)}
                                  >
                                    View
                                  </a>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      )}
                    </table>
                    {(storageAccountsFilteredData || [])?.length === 0 && 
                    <EmptyView />
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          id="cloudResGraphMonitor"
          className={commonService.classNames(
            currentTabSelection !== "Graph Monitor" ? "hidden" : "",
            "my-4 px-4"
          )}
        >
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto px-8">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Graph Monitor
              </h1>
            </div>
          </div>
          <div className="flow-root">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-2">
                {loadingGraphData ? (
                  <Pulse show={loadingGraphData} />
                ) : (
                  <>
                    <div className="flex items-center">
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="subscriptionForMonitorData"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Category
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="subscriptionForMonitorData"
                            name="subscriptionForMonitorData"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={category}
                            onChange={(e) => {
                              onChangeCategory(e);
                            }}
                          >
                            <option value={all} key="category">
                              {`${all} | ${monitorData?.length}`}
                            </option>
                            {(categoryData || []).map(
                              (category: CategoryList) => (
                                <option value={category?.id} key={category?.id}>
                                  {`${category?.name} | ${category.count}`}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="subscriptionForMonitorData"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Subscriptions
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="subscriptionForMonitorData"
                            name="subscriptionForMonitorData"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={subscriptionForMonitorData}
                            onChange={(e) => {
                              onChangeSubscriptionForMonitorData(e);
                            }}
                          >
                            <option value={all} key="sunscription">
                              {all}
                            </option>
                            {subscriptionListForMonitorData.map(
                              (sub: {
                                name: string;
                                id: string;
                                rg: string[];
                              }) => (
                                <option value={sub?.id} key={sub?.id}>
                                  {sub?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="resourceGroupForMonitorData"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          RG
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="resourceGroupForMonitorData"
                            name="resourceGroupForMonitorData"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={resourceGroupForMonitorData}
                            onChange={(e) => {
                              onChangeResourceGroupForMonitorData(e);
                            }}
                          >
                            <option value={all} key="rg">
                              {all}
                            </option>
                            {resourceGroupListForMonitorData.map(
                              (rg: string) => (
                                <option value={rg} key={rg}>
                                  {rg}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Query Name"
                              sortName="queryName"
                              sortBy={monitorSortBy}
                              asc={monitorAsc}
                              filterVal={monitorFilterVal.queryName}
                              onSortChange={onMonitorSortChange}
                              onFilterChange={onMonitorFilterChange}
                            />
                            {/* Query Name */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Subscription"
                              sortName="subscriptionName"
                              sortBy={monitorSortBy}
                              asc={monitorAsc}
                              filterVal={monitorFilterVal.subscriptionName}
                              onSortChange={onMonitorSortChange}
                              onFilterChange={onMonitorFilterChange}
                            />
                            {/* Subscription */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Type"
                              sortName="type"
                              sortBy={monitorSortBy}
                              asc={monitorAsc}
                              filterVal={monitorFilterVal.type}
                              onSortChange={onMonitorSortChange}
                              onFilterChange={onMonitorFilterChange}
                            />
                            {/* Type */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Location"
                              sortName="location"
                              sortBy={monitorSortBy}
                              asc={monitorAsc}
                              filterVal={monitorFilterVal.location}
                              onSortChange={onMonitorSortChange}
                              onFilterChange={onMonitorFilterChange}
                            />
                            {/* Location */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            {/* Resource Group */}
                            <TableHead
                              name="Resource Group"
                              sortName="resourceGroup"
                              sortBy={monitorSortBy}
                              asc={monitorAsc}
                              filterVal={monitorFilterVal.resourceGroup}
                              onSortChange={onMonitorSortChange}
                              onFilterChange={onMonitorFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            {/* First Found On */}
                            <TableHead
                              name="First Found On"
                              sortName="firstFoundOn"
                              sortBy={monitorSortBy}
                              asc={monitorAsc}
                              filterVal={monitorFilterVal.firstFoundOn}
                              onSortChange={onMonitorSortChange}
                              onFilterChange={onMonitorFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Last Seen"
                              sortName="lastSeen"
                              sortBy={monitorSortBy}
                              asc={monitorAsc}
                              filterVal={monitorFilterVal.lastSeen}
                              onSortChange={onMonitorSortChange}
                              onFilterChange={onMonitorFilterChange}
                            />
                            {/* Last Seen */}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Additional Info
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Snapshot Info
                          </th>
                        </tr>
                      </thead>

                      {(graphData || [])?.length > 0 && (
                        <tbody className="divide-y divide-gray-200">
                          {(graphData || [])?.map((item, i) => (
                            <tr key={item.id}>
                              <td className="px-3 py-2 text-sm font-small text-gray-900">
                                {item?.queryName}
                              </td>
                              <td className="px-3 py-2 text-sm font-small text-gray-900">
                                {item?.subscriptionName}
                              </td>
                              <td className="px-3 py-2 text-sm font-small text-gray-900">
                                {item?.type}
                              </td>
                              <td className="px-3 py-2 text-sm font-small text-gray-900">
                                {item?.location}
                              </td>
                              <td className="px-3 py-2 text-sm font-small text-gray-900">
                                {item?.resourceGroup}
                              </td>
                              <td className="px-3 py-2 text-sm font-small text-gray-900">
                                {new Date(item?.firstFoundOn).toLocaleString()}
                              </td>
                              <td className="px-3 py-2 text-sm font-small text-gray-900">
                                {new Date(item?.lastSeen).toLocaleString()}
                              </td>
                              <td className="px-3 py-2 text-sm font-small text-gray-900">
                                <div className="flex items-center">
                                  <a
                                    href="#"
                                    className="text-indigo-600 hover:text-indigo-900 mr-3"
                                    onClick={() =>
                                      openQueryInfoModal(item?.additionalData)
                                    }
                                  >
                                    View
                                  </a>
                                  <ResourceLink
                                    link={item?.resourceLink}
                                    index={i}
                                  />
                                </div>
                              </td>
                              <td className="px-3 py-2 text-sm font-small text-gray-900">
                                <a
                                  href="#"
                                  className="text-indigo-600 hover:text-indigo-900 mr-3"
                                  onClick={() => openSnapshotModal(item)}
                                >
                                  View
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                    {(graphData || [])?.length === 0 && <EmptyView />}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
